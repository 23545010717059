<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="eventAggregatesError.main && eventAggregatesError.main.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Temporary message for amount columns issue -->
    <div v-if="hideAmountColumns" class="alert alert-warning">
      <div>{{ $t('shared.warnings.amountsHidden1') }}</div>
      <div>{{ $t('shared.warnings.amountsHidden2') }}</div>
      <div>{{ $t('shared.warnings.amountsHidden3') }}</div>
    </div>

    <!-- First row with group selector, date range pickers, compare button -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Group tree select -->
      <div class="flex-fill flex-basis-0">
        <treeselect
          :limit-text="treeSelectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelect"
          :searchable="false"
          style="max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Date range pickers -->
      <div class="flex-fill flex-basis-0 text-center">
        <div class="btn-group text-nowrap">
          <stats-date-range-picker v-model="dateRanges.main" />
        </div>
        <div class="mt-2" v-if="compare">
          <stats-date-range-picker v-model="dateRanges.compare" :compare="true" />
        </div>
      </div>

      <!-- Compare button -->
      <div class="flex-fill flex-basis-0 text-right">
        <button @click="toggleCompare()" class="btn btn-primary">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="columns" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-columns fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64V160h160v256zm224 0H288V160h160v256z" class=""></path></svg>
          {{ $t('views.stats.tunnel.compare') }}
        </button>
      </div>
    </div>

    <!-- Dashboard-style big numbers -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <div v-for="action in ['print_button', 'print', 'redirection']" :key="action" class="card flex-fill flex-basis-0">
        <div class="card-body">
          <div class="position-relative">
            <div class="pt-3 mb-0">
              <!-- Main count -->
              <template v-if="groupsLoading || eventAggregatesLoading.main">
                <md-spinner md-indeterminate :diameter="27" :stroke-width="5" />
              </template>
              <template v-else>
                <h3 class="text-primary mb-0 d-inline">{{ actionCount('main', action) | number }}</h3>
              </template>

              <!-- Compare count -->
              <template v-if="compare">
                <h5 class="mb-0 d-inline">
                /
                <template v-if="groupsLoading || eventAggregatesLoading.compare">
                  <md-spinner md-indeterminate :diameter="16" :stroke-width="5" />
                </template>
                <template v-else>
                  <span class="text-orange">{{ actionCount('compare', action) | number }}</span>
                </template>
                </h5>
              </template>
            </div>
            <div class="pb-3">
              {{ $t(`shared.eventActions.widget.${action}`) }}

              <!-- Compare percentage -->
              <div v-if="compare">
                <template v-if="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare">
                  <md-spinner md-indeterminate :diameter="16" :stroke-width="5" />
                </template>
                <template v-else>
                  <small class="font-weight-bold" :class="variationClass(variation(actionCount('main', action), actionCount('compare', action)))">
                    {{ variation(actionCount('main', action), actionCount('compare', action)) | variation }}
                  </small>
                </template>
              </div>
            </div>
            <h1 class="position-absolute w-100 text-right mb-0 text-secondary" style="bottom: 0;">
              <template v-if="action === 'print_button'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="desktop" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-desktop fa-w-18"><path fill="currentColor" d="M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z" class=""></path></svg>
              </template>
              <template v-else-if="action === 'print'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mouse" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-mouse fa-w-12"><path fill="currentColor" d="M0 352a160 160 0 0 0 160 160h64a160 160 0 0 0 160-160V224H0zM176 0h-16A160 160 0 0 0 0 160v32h176zm48 0h-16v192h176v-32A160 160 0 0 0 224 0z" class=""></path></svg>
              </template>
              <template v-else-if="action === 'redirection'">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-circle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-arrow-alt-circle-right fa-w-16"><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z" class=""></path></svg>
              </template>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Line chart -->
    <div class="mb-3">
      <div class="card">
        <div class="card-body">
          <div class="position-relative">
            <highcharts
              :options="chartOptions"
              class="chart"
              :aria-busy="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare"
              style="width: 100%; height: 300px;" />
            <div v-if="groupsLoading || eventAggregatesLoading.main || eventAggregatesLoading.compare" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 0;">
              <md-spinner md-indeterminate />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Columns + export button -->
    <div class="mb-3 d-flex justify-content-between align-items-center gutter">
      <div>
        <div class="d-flex gutter">
          <!-- Columns button -->
          <button type="button" class="btn btn-primary" v-b-modal.columns-modal>
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-table fa-w-16"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM160 448H48c-8.837 0-16-7.163-16-16v-80h128v96zm0-128H32v-96h128v96zm0-128H32V96h128v96zm160 256H192v-96h128v96zm0-128H192v-96h128v96zm0-128H192V96h128v96zm160 160v80c0 8.837-7.163 16-16 16H352v-96h128zm0-32H352v-96h128v96zm0-128H352V96h128v96z" class=""></path></svg>
            {{ $t('shared.actions.manageColumns') }}
          </button>
        </div>
      </div>
      <span class="d-inline-block"  v-b-tooltip="{ title: $t('shared.tooltip.exportDemoMode') , trigger: 'hover', placement: 'top', disabled: !demoMode }">
        <button @click="exportXLSX" type="button" class="btn btn-primary" :disabled="demoMode">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </span>
    </div>

    <!-- Columns modal -->
    <b-modal
      id="columns-modal"
      size="sm"
      :title="$t('shared.actions.manageColumns')"
      hide-footer
      scrollable
      no-fade>
      <template v-for="columnSetting in availableColumnSettings">
        <div class="form-group" :key="columnSetting">
          <div class="custom-control custom-checkbox">
            <input
              v-model="columnSettings[columnSetting]"
              type="checkbox" class="custom-control-input" :id="`column-${columnSetting}`">
            <label class="custom-control-label" :for="`column-${columnSetting}`">
              <template v-if="columnSetting === 'rawPackaging'">
                {{ $t(`attributes.productLanguageData.${columnSetting}`) }}
              </template>
              <template v-else-if="['reference', 'ean', 'upc'].includes(columnSetting)">
                {{ $t(`attributes.product.${columnSetting}`) }}
              </template>
              <template v-else-if="columnSetting === 'rates'">
                {{ $t('views.stats.tunnel.rates') }}
              </template>
              <template v-else-if="columnSetting === 'redirectionProduct'">
                {{ $t('shared.eventActions.widget.redirection_product') }}
              </template>
            </label>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Table -->
    <div class="table-responsive">
      <table
        class="table table-bordered table-hover-tbody"
        :aria-busy="eventAggregatesLoading.main || eventAggregatesLoading.compare"><!-- TODO: spinner somewhere -->
        <thead>
          <tr>
            <th class="cell-md">{{ $t('views.stats.tunnel.group') }}</th>
            <th class="cell-lg">{{ $t('views.stats.tunnel.widget') }}</th>
            <th class="cell-md" v-if="columnSettings.rawPackaging">{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
            <th class="cell-md" v-if="columnSettings.reference">{{ $t('attributes.product.reference') }}</th>
            <th class="cell-md" v-if="columnSettings.ean">{{ $t('attributes.product.ean') }}</th>
            <th class="cell-md" v-if="columnSettings.upc">{{ $t('attributes.product.upc') }}</th>
            <th @click="setSort('printButton')" :aria-sort="ariaSort('printButton')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.print_button') | capitalize }}
            </th>
            <th @click="setSort('print')" :aria-sort="ariaSort('print')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.print') | capitalize }}
            </th>
            <template v-if="columnSettings.rates">
              <th @click="setSort('printRate')" :aria-sort="ariaSort('printRate')" class="data-cell cursor-pointer">
                {{ $t('shared.eventActions.widget.print_rate') | capitalize }}
              </th>
            </template>
            <template v-if="hasStoreActivated">
              <th @click="setSort('storeSelection')" :aria-sort="ariaSort('storeSelection')" class="data-cell cursor-pointer">
                {{ $t('shared.eventActions.widget.store_selection') | capitalize }}
              </th>
              <template v-if="columnSettings.rates">
                <th @click="setSort('storeSelectionRate')" :aria-sort="ariaSort('storeSelectionRate')" class="data-cell cursor-pointer">
                  {{  $t('shared.eventActions.widget.store_selection_rate') | capitalize }}
                </th>
              </template>
            </template>
            <th @click="setSort('redirection')" :aria-sort="ariaSort('redirection')" class="data-cell cursor-pointer">
              {{ $t('shared.eventActions.widget.redirection') | capitalize }}
            </th>
            <template v-if="columnSettings.rates">
              <th @click="setSort('redirectionRate')" :aria-sort="ariaSort('redirectionRate')" class="data-cell cursor-pointer">
                {{ $t('shared.eventActions.widget.redirection_rate') | capitalize }}
              </th>
            </template>
            <template v-if="columnSettings.redirectionProduct">
              <th @click="setSort('redirectionProduct')" :aria-sort="ariaSort('redirectionProduct')" class="data-cell cursor-pointer">
                {{ $t('shared.eventActions.widget.redirection_product') | capitalize }}
              </th>
            </template>
            <template v-if="userCanReadEngagementAmount && !hideAmountColumns">
              <th @click="setSort('engagedRevenue')" :aria-sort="ariaSort('engagedRevenue')" class="data-cell cursor-pointer th-shield" ref="thEngagedRevenue">
                {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize  }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <b-tooltip v-if="userIsAdmin" :target="() => $refs['thEngagedRevenue']" boundary="viewport">
                {{ $t('views.stats.tunnel.tooltip.engaged_revenue') }}
                <sup class="text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </sup>
              </b-tooltip>
            </template>
            <template v-if="userCanReadValidations">
              <th @click="setSort('validation')" :aria-sort="ariaSort('validation')" class="data-cell cursor-pointer th-shield">
                {{ $t('shared.eventActions.widget.validation') | capitalize }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <template v-if="columnSettings.rates">
                <th @click="setSort('validationRate')" :aria-sort="ariaSort('validationRate')" class="data-cell cursor-pointer">
                  {{ $t('shared.eventActions.widget.validation_rate') | capitalize }}
                  <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                  </div>
                </th>
              </template>
              <th v-if="!hideAmountColumns" @click="setSort('revenue')" :aria-sort="ariaSort('revenue')" class="data-cell cursor-pointer th-shield" ref="thRevenue">
                {{ $t('shared.eventActions.widget.revenue') | capitalize }}
                <div v-if="userIsAdmin && !demoMode" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <b-tooltip v-if="userIsAdmin && !demoMode" :target="() => $refs['thRevenue']" boundary="viewport">
                {{ $t('views.stats.tunnel.tooltip.revenue') }}
                <sup class="text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </sup>
              </b-tooltip>
            </template>
          </tr>
        </thead>
        <template v-if="groupsLoading">
          <tbody>
            <tr>
              <td :colspan="colspan" class="text-center">
                <md-spinner md-indeterminate />
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else v-for="group in displayedGroups">
          <!-- Group total -->
          <tbody :key="`group-${group.id}`">
            <tr v-for="dataset in datasets" :key="dataset" class="font-weight-semibold bg-light">
              <td
                :rowspan="compare ? 2 : 1"
                @click="toggleExpandedGroup(group.id)"
                v-if="!compare || compare && (dataset === 'main')"
                :class="{ 'cursor-pointer': group.children || group.widgets.length > 0 }">
                <div class="d-flex gutter-sm justify-content-between align-items-center">
                  <div v-if="group.depth > 0">
                    <template v-for="n in group.depth">
                      <svg :key="n" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                    </template>
                  </div>

                  <div class="flex-fill">
                    <region-flag v-if="international" :code="group.region.code" />
                    {{ group.name }}
                  </div>

                  <template v-if="group.children || group.widgets.length > 0">
                    <template v-if="!expandedGroup(group.id)">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                    </template>
                    <template v-else>
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                    </template>
                  </template>
                </div>
              </td>
              <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">Total</td>
              <td :rowspan="compare ? 2 : 1" v-if="columnSettings.rawPackaging && (!compare || compare && (dataset === 'main'))"></td>
              <td :rowspan="compare ? 2 : 1" v-if="columnSettings.reference && (!compare || compare && (dataset === 'main'))"></td>
              <td :rowspan="compare ? 2 : 1" v-if="columnSettings.ean && (!compare || compare && (dataset === 'main'))"></td>
              <td :rowspan="compare ? 2 : 1" v-if="columnSettings.upc && (!compare || compare && (dataset === 'main'))"></td>
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'print_button') | number }}</td>
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'print') | number }}</td>
              <template v-if="columnSettings.rates">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'print', 'print_button') | percentage }}</td>
              </template>
              <template v-if="hasStoreActivated">
                <td class="text-right" :class="rowClass(dataset)">
                  <template v-if="group.hasStoreActivated">
                    {{ groupActionCount(dataset, group.id, 'store_selection') | number }}
                  </template>
                  <template v-else>
                    –
                  </template>
                </td>
                <template v-if="columnSettings.rates">
                  <td class="text-right" :class="rowClass(dataset)">
                    <template v-if="group.hasStoreActivated">
                      {{ groupActionRate(dataset, group.id, 'store_selection', 'print') | percentage }}
                    </template>
                    <template v-else>
                      –
                    </template>
                  </td>
                </template>
              </template>
              <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'redirection') | number }}</td>
              <template v-if="columnSettings.rates">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'redirection', 'print') | percentage }}</td>
              </template>
              <template v-if="columnSettings.redirectionProduct">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'redirection_product') | number }}</td>
              </template>
              <template v-if="userCanReadEngagementAmount && !hideAmountColumns">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionAmount(dataset, group.id, 'redirection_product') | price(group.region) }}</td>
              </template>
              <template v-if="userCanReadValidations">
                <td class="text-right" :class="rowClass(dataset)">{{ groupActionCount(dataset, group.id, 'validation') | number }}</td>
                <template v-if="columnSettings.rates">
                  <td class="text-right" :class="rowClass(dataset)">{{ groupActionRate(dataset, group.id, 'validation', 'redirection') | percentage }}</td>
                </template>
                <td class="text-right" :class="rowClass(dataset)" v-if="!hideAmountColumns">{{ groupActionAmount(dataset, group.id, 'validation_product') | price(group.region) }}</td>
              </template>
            </tr>
          </tbody>

          <!-- Group widgets -->
          <template v-if="expandedGroup(group.id)">
            <tbody v-for="widget in sortWidgets(group.widgets)" :key="`widget-${widget.id}`">
              <template v-for="dataset in datasets">
                <tr :key="dataset">
                  <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">{{ group.name }}</td>
                  <td :rowspan="compare ? 2 : 1" v-if="!compare || compare && (dataset === 'main')">
                    <div class="d-flex justify-content-between gutter-sm">
                      <div>
                        {{ widget.name }}
                      </div>
                      <div v-if="userIsAdmin">
                        <router-link :to="{ name: 'widget', params: { id: widget.id } }" class="text-light">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16"><path fill="currentColor" d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" class=""></path></svg>
                        </router-link>
                      </div>
                    </div>
                  </td>
                  <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.rawPackaging && (!compare || compare && (dataset === 'main'))">
                    <small>{{ widget.product.productLanguageDatas[0].rawPackaging }}</small>
                  </td>
                  <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.reference && (!compare || compare && (dataset === 'main'))">
                    <small>{{ widget.product.reference }}</small>
                  </td>
                  <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.ean && (!compare || compare && (dataset === 'main'))">
                    <small>{{ widget.product.ean }}</small>
                  </td>
                  <td :rowspan="compare ? 2 : 1"  v-if="columnSettings.upc && (!compare || compare && (dataset === 'main'))">
                    <small>{{ widget.product.upc }}</small>
                  </td>
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('printButton', dataset, widget) | number }}</td>
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('print', dataset, widget) | number }}</td>
                  <template v-if="columnSettings.rates">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('printRate', dataset, widget) | percentage }}</td>
                  </template>
                  <template v-if="hasStoreActivated">
                    <td class="text-right" :class="rowClass(dataset)">
                      <template v-if="group.hasStoreActivated">
                        {{ widgetData('storeSelection', dataset, widget) | number }}
                      </template>
                      <template v-else>
                        –
                      </template>
                    </td>
                    <template v-if="columnSettings.rates">
                      <td class="text-right" :class="rowClass(dataset)">
                        <template v-if="group.hasStoreActivated">
                          {{ widgetData('storeSelectionRate', dataset, widget) | percentage }}
                        </template>
                        <template v-else>
                          –
                        </template>
                      </td>
                    </template>
                  </template>
                  <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirection', dataset, widget) | number }}</td>
                  <template v-if="columnSettings.rates">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirectionRate', dataset, widget) | percentage }}</td>
                  </template>
                  <template v-if="columnSettings.redirectionProduct">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('redirectionProduct', dataset, widget) | number }}</td>
                  </template>
                  <template v-if="userCanReadEngagementAmount && !hideAmountColumns">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('engagedRevenue', dataset, widget) | price(group.region) }}</td>
                  </template>
                  <template v-if="userCanReadValidations">
                    <td class="text-right" :class="rowClass(dataset)">{{ widgetData('validation', dataset, widget) | number }}</td>
                    <template v-if="columnSettings.rates">
                      <td class="text-right" :class="rowClass(dataset)">{{ widgetData('validationRate', dataset, widget) | percentage }}</td>
                    </template>
                    <td v-if="!hideAmountColumns" class="text-right" :class="rowClass(dataset)">{{ widgetData('revenue', dataset, widget) | price(group.region) }}</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../../shared/MdSpinner.vue'
import RegionFlag from '../../shared/RegionFlag.vue'
import StatsDateRangePicker from '../../shared/StatsDateRangePicker.vue'
import Treeselect from '@riophae/vue-treeselect'
import { Chart } from 'highcharts-vue'
import { VBTooltip, BTooltip, BModal, VBModal } from 'bootstrap-vue'
import LocaleCurrency from 'locale-currency'
import getSymbolFromCurrency from 'currency-symbol-map'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import i18n from '../../../i18n'

export default {
  components: { MdSpinner, RegionFlag, StatsDateRangePicker, Treeselect, highcharts: Chart, BTooltip, BModal },
  directives: { 'b-tooltip': VBTooltip, 'b-modal': VBModal },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      eventAggregates: {
        main: [],
        compare: []
      },
      eventAggregatesLoading: {
        main: false,
        compare: false
      },
      eventAggregatesError: {
        main: null,
        compare: null
      },
      eventAggregatesDaily: {
        main: [],
        compare: []
      },
      eventAggregatesDailyLoading: {
        main: false,
        compare: false
      },
      eventAggregatesDailyError: {
        main: null,
        compare: null
      },
      dateRanges: {
        main: {
          startDate: null,
          endDate: null
        },
        compare: {
          startDate: null,
          endDate: null
        }
      },
      compare: false,
      selectedGroupIds: [],
      expandedGroupIds: [parseInt(this.$route.params.groupId)],
      sortKey: null,
      sortDirection: null,
      columnSettings: {},
      availableColumnSettings: ['rawPackaging', 'reference', 'ean', 'upc', 'rates', 'redirectionProduct']
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // Analytics data global loading
    analyticsDataGlobalLoading: function() {
      return this.eventAggregatesLoading.main || this.eventAggregatesLoading.compare
    },
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userIsMichelin: function() {
      return this.roles.includes('michelin')
    },
    userIsValidationReader: function() {
      return this.roles.includes('validations_reader')
    },
    userCanReadEngagementAmount: function() {
      return (this.userIsAdmin && !this.demoMode) || this.userIsMichelin
    },
    userCanReadValidations: function() {
      return this.userIsAdmin || this.userIsValidationReader
    },
    hasStoreActivated: function() {
      return this.group ? this.group.hasStoreActivated : null
    },
    demoMode: function() {
      return this.$route.query.demo === 'true'
    },
    // Hide amount columns for non-admin users if dateRange intersects with 2025-01-04 - 2025-01-09
    hideAmountColumns: function() {
      const mainDateRangeIntersects = this.dateRanges.main.startDate <= new Date('2025-01-10') && this.dateRanges.main.endDate >= new Date('2025-01-04')
      const compareDateRangeIntersects = this.dateRanges.compare.startDate <= new Date('2025-01-10') && this.dateRanges.compare.endDate >= new Date('2025-01-04')
      const wasAccessingAmountColumns = this.userCanReadEngagementAmount || this.userCanReadValidations
      return !this.userIsAdmin && wasAccessingAmountColumns && (mainDateRangeIntersects || compareDateRangeIntersects)
    },
    colspan: function() {
      let colspan = 6

      if (this.columnSettings.rawPackaging) colspan++
      if (this.columnSettings.reference) colspan++
      if (this.columnSettings.ean) colspan++
      if (this.columnSettings.upc) colspan++
      if (this.columnSettings.rates) colspan += 2
      if (this.columnSettings.redirectionProduct) colspan++
      if (this.hasStoreActivated) colspan++
      if (this.hasStoreActivated && this.columnSettings.rates) colspan++
      if (this.userCanReadEngagementAmount) colspan++
      if (this.userCanReadValidations) {
        colspan += 2
        if (this.columnSettings.rates) colspan++
      }

      return colspan
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Group helpers
    groupIds: function() {
      return this.groups.map(group => group.id)
    },
    groupsTree: function() {
      let groupsTree = {}

      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })[0]
      }

      return groupsTree
    },
    // Format group tree for vue treeselect component
    groupsTreeSelect: function() {
      let groupsTreeSelect = []

      if (this.groups.length > 0) {
        groupsTreeSelect = arrayToTree(this.groups.map(group => {
          return {
            id: group.id,
            label: {
              name: group.name,
              regionCode: group.region.code
            },
            parent_id: group.parentId
          }
        }))
      }

      return groupsTreeSelect
    },
    // Used to fastly check if a group is selected (avoid iterating over selectedGroupIds again and again)
    selectedGroupIdsSet: function() {
      return new Set(this.selectedGroupIds)
    },
    // Used to fastly check if a group is expanded (avoid iterating over expandedGroupIds again and again)
    expandedGroupIdsSet: function() {
      return new Set(this.expandedGroupIds)
    },
    // Get selected groups
    selectedGroups: function() {
      return this.groups.filter(group => this.selectedGroupIdsSet.has(group.id))
    },
    // Get selected groups in tree order
    selectedGroupsOrdered: function() {
      const selectedGroupsOrdered = []

      const appendGroup = (group, depth) => {
        if (this.selectedGroupIdsSet.has(group.id)) {
          group.depth = depth
          selectedGroupsOrdered.push(group)
        }
        if (group.children) {
          group.children.map(group => appendGroup(group, depth + 1))
        }
      }
      appendGroup(this.groupsTree, 0)
      return selectedGroupsOrdered
    },
    // Get groups to display (= selected & expanded & parents expanded) & add depth property
    displayedGroups: function() {
      const groups = []

      const appendGroup = (group, depth) => {
        if (this.selectedGroupIdsSet.has(group.id)) {
          group.depth = depth
          groups.push(group)
        }
        if (this.expandedGroup(group.id) && group.children) {
          group.children.map(group => appendGroup(group, depth + 1))
        }
      }
      appendGroup(this.groupsTree, 0)
      return groups
    },
    // Get selected widgets
    selectedWidgets: function() {
      return this.selectedGroups.map(group => group.widgets).flat(Infinity)
    },
    // Get selected widgetIds
    selectedWidgetIds: function() {
      return this.selectedWidgets.map(widget => widget.id)
    },
    // Used to fastly check if a widget is selected (avoid iterating over selectedWidgetIds again and again)
    selectedWidgetIdsSet: function() {
      return new Set(this.selectedWidgetIds)
    },
    // Get {group & descendants) widgets (precomputed Map)
    groupWidgetsMap: function() {
      const groupWidgetsMap = new Map()

      // Explore group tree recursively and collect widgets
      const exploreTree = group => {
        if (group.children) {
          const widgets = [...group.widgets]

          group.children.forEach(child => {
            exploreTree(child)

            widgets.push(...groupWidgetsMap.get(child.id))
          })

          groupWidgetsMap.set(group.id, widgets)
        } else {
          groupWidgetsMap.set(group.id, group.widgets)
        }
      }

      exploreTree(this.groupsTree)

      return groupWidgetsMap
    },
    // Compare helpers
    datasets: function() {
      return this.compare ? ['main', 'compare'] : ['main']
    },
    // Convert daily event stats into total values as a hash
    analyticsDataEventsMainHash: function() { // TODO: replace with a js Map
      const hash = {}

      this.eventAggregates.main.forEach(item => {
        const action = item._id.action
        const mediumId = item._id.mediumId
        hash[action] = hash[action] || {}
        hash[action][mediumId] = hash[action][mediumId] || { count: 0, amount: 0 }
        hash[action][mediumId].count += item.count
        if (item.amount) hash[action][mediumId].amount += item.amount
      })

      return hash
    },
    analyticsDataEventsCompareHash: function() { // TODO: replace with a js Map
      const hash = {}

      this.eventAggregates.compare.forEach(item => {
        const action = item._id.action
        const mediumId = item._id.mediumId
        hash[action] = hash[action] || {}
        hash[action][mediumId] = hash[action][mediumId] || { count: 0, amount: 0 }
        hash[action][mediumId].count += item.count
        if (item.amount) hash[action][mediumId].amount += item.amount
      })

      return hash
    },
    // Chart
    chartOptions: function() {
      const chartOptions = {
        chart: { type: 'line' },
        xAxis: [{
          type: 'datetime',
          id: 'x1'
        }, {
          type: 'datetime',
          id: 'x2',
          opposite: true
        }],
        yAxis: {
          title: null,
          min: 0
        },
        title: null,
        credits: { enabled: false },
        series: [{
          name: this.$t('shared.eventActions.widget.print_button'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#304269',
          data: this.actionTimeSerie('main', 'print_button')
        }, {
          name: this.$t('shared.eventActions.widget.print'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#576c99',
          data: this.actionTimeSerie('main', 'print')
        }, {
          name: this.$t('shared.eventActions.widget.redirection'),
          xAxis: 'x1',
          marker: { radius: 3, symbol: 'circle' },
          color: '#869ac4',
          data: this.actionTimeSerie('main', 'redirection')
        }]
      }

      if (this.compare) {
        chartOptions.series.push({
          name: this.$t('shared.eventActions.widget.print_button'),
          xAxis: 'x2',
          marker: { radius: 3, symbol: 'circle' },
          color: '#ea5631',
          data: this.actionTimeSerie('compare', 'print_button')
        })
        chartOptions.series.push({
          name: this.$t('shared.eventActions.widget.print'),
          xAxis: 'x2',
          marker: { radius: 3, symbol: 'circle' },
          color: '#ee856a',
          data: this.actionTimeSerie('compare', 'print')
        })
        chartOptions.series.push({
          name: this.$t('shared.eventActions.widget.redirection'),
          xAxis: 'x2',
          marker: { radius: 3, symbol: 'circle' },
          color: '#f6b8a9',
          data: this.actionTimeSerie('compare', 'redirection')
        })
      }

      // Compare: adjust axis extremes to keep same step
      if (this.compare && this.dateRanges.compare.startDate && this.dateRanges.compare.endDate) {
        const length = Math.max(
          this.dateRanges.main.endDate.valueOf() - this.dateRanges.main.startDate.valueOf(),
          this.dateRanges.compare.endDate.valueOf() - this.dateRanges.compare.startDate.valueOf()
        )
        const padding = length * 0.01

        chartOptions.xAxis[0].min = this.dateRanges.main.startDate.valueOf() - padding
        chartOptions.xAxis[0].max = this.dateRanges.main.startDate.valueOf() + padding + length
        chartOptions.xAxis[1].min = this.dateRanges.compare.startDate.valueOf() - padding
        chartOptions.xAxis[1].max = this.dateRanges.compare.startDate.valueOf() + padding + length
      }

      return chartOptions
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Treeselect text limit formatter
    treeSelectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    // Load groups
    loadGroups: async function() {
      this.groupsLoading = true
      this.groupsError = null

      try {
        const res = await fetch(`/api/interface/groups?group_id=${this.$route.params.groupId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
        const data = await res.json()

        if (!res.ok) {
          this.groupsError = { status: res.status, data }
        } else {
          if (this.demoMode) {
            this.groups = Object.freeze(data.map((group, index) => {
              group.name = `group-${index + 1}`
              group.widgets.forEach((widget, index) => {
                widget.name = `widget-${index + 1}`

                if (widget.product) {
                  widget.product.reference = `reference-${index + 1}`
                  widget.product.ean = `ean-${index + 1}`
                  widget.product.upc = `upc-${index + 1}`
                  widget.product.productLanguageDatas[0].rawPackaging = `raw-packaging-${index + 1}`
                }
              })

              return group
            }))
          } else {
            this.groups = Object.freeze(data)
          }

          // Init group treeselect value
          this.selectedGroupIds = data.map(group => group.id)
        }
      } catch (err) {
        this.groupsError = err
      } finally {
        this.groupsLoading = false
      }
    },
    // Load groups with GraphQL - slow
    loadGroupsGql: async function() {
      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsTunnelGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
          widgets {
            id
            name
            product {
              id
              reference
              ean
              upc
              productLanguageDatas {
                id
                name
                rawPackaging
              }
            }
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: { id: parseInt(this.$route.params.groupId) }
          })
        })
        const data = await res.json()

        if (!res.ok) {
          this.groupsError = { status: res.status, data }
        } else {
          this.groups = Object.freeze(data.data.groups)

          // Init group treeselect value
          this.selectedGroupIds = this.groups.map(group => group.id)
        }
      } catch (err) {
        this.groupsError = err
      } finally {
        this.groupsLoading = false
      }
    },
    // Load event aggregates
    loadEventAggregates: async function(dataset) {
      this.eventAggregatesLoading[dataset] = true
      this.eventAggregatesError[dataset] = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRanges[dataset].startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRanges[dataset].endDate).endOf('day').format('x'),
        interval: 'total',
        group_keys: ['action', 'mediumId'],
        group_id: this.$route.params.groupId
      }

      const loadClassicActions = async () => {
        const body = {
          ...baseBody,
          actions: ['print_button', 'print', 'redirection', 'redirection_product', 'validation', 'validation_product']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadStoreSelections = async () => {
        const body = {
          ...baseBody,
          actions: ['retail_outlet_selection'],
          store: true
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [data, storeSelectionData] = await Promise.all([loadClassicActions(), loadStoreSelections()])

        storeSelectionData.forEach(item => {
          item._id.action = 'store_selection'
        })

        // Set events in store
        this.eventAggregates[dataset] = Object.freeze(data.concat(storeSelectionData))
      } catch (err) {
        this.eventAggregatesError[dataset] = err
      } finally {
        this.eventAggregatesLoading[dataset] = false
      }
    },
    // Load event aggregates daily
    loadEventAggregatesDaily: async function(dataset) {
      this.eventAggregatesDailyLoading[dataset] = true
      this.eventAggregatesDailyError[dataset] = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRanges[dataset].startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRanges[dataset].endDate).endOf('day').format('x'),
        interval: 'daily',
        group_keys: ['action', 'groupId'],
        group_id: this.$route.params.groupId
      }

      const loadClassicActions = async () => {
        const body = {
          ...baseBody,
          actions: ['print_button', 'print', 'redirection', 'redirection_product', 'validation', 'validation_product']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadStoreSelections = async () => {
        const body = {
          ...baseBody,
          actions: ['retail_outlet_selection'],
          store: true
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [data, storeSelectionData] = await Promise.all([loadClassicActions(), loadStoreSelections()])

        // Merge events with store selection
        storeSelectionData.forEach(storeSelectionDailyItem => {
          storeSelectionDailyItem.data.forEach(item => {
            item.action = 'store_selection'
          })

          const dailyItem = data.find(item => {
            return item._id.year === storeSelectionDailyItem._id.year &&
              item._id.month === storeSelectionDailyItem._id.month &&
              item._id.day === storeSelectionDailyItem._id.day
          })

          if (dailyItem) {
            dailyItem.data = dailyItem.data.concat(storeSelectionDailyItem.data)
          } else {
            data.push(storeSelectionDailyItem)
          }
        })

        this.eventAggregatesDaily[dataset] = Object.freeze(data)
      } catch (err) {
        this.eventAggregatesDailyError[dataset] = err
      } finally {
        this.eventAggregatesDailyLoading[dataset] = false
      }
    },
    // Compare button
    toggleCompare: function() {
      this.compare = !this.compare
    },
    // Group button
    toggleExpandedGroup: function(groupId) {
      if (this.expandedGroupIdsSet.has(groupId)) {
        this.expandedGroupIds = this.expandedGroupIds.filter(id => id !== groupId)
      } else {
        this.expandedGroupIds.push(groupId)
      }
    },
    // Check if a group is expanded
    expandedGroup: function(groupId) {
      return this.expandedGroupIdsSet.has(groupId)
    },
    // Export table as XLSX
    exportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_tunnel_${this.dateSerializer(this.dateRanges.main.startDate)}_${this.dateSerializer(this.dateRanges.main.endDate)}.xlsx`

      // Build headers
      const headerKeys = ['group', 'region', 'depth', 'widget']
      if (this.columnSettings.rawPackaging) headerKeys.push('rawPackaging')
      if (this.columnSettings.reference) headerKeys.push('reference')
      if (this.columnSettings.ean) headerKeys.push('ean')
      if (this.columnSettings.upc) headerKeys.push('upc')
      headerKeys.push('print_button')
      headerKeys.push('print')
      if (this.columnSettings.rates) headerKeys.push('print_rate')
      if (this.hasStoreActivated) {
        headerKeys.push('store_selection')
        if (this.columnSettings.rates) headerKeys.push('store_selection_rate')
      }
      headerKeys.push('redirection')
      if (this.columnSettings.rates) headerKeys.push('redirection_rate')
      if (this.columnSettings.redirectionProduct) headerKeys.push('redirection')
      if (this.userCanReadEngagementAmount && !this.hideAmountColumns) {
        headerKeys.push('redirection_amount')
      }
      if (this.userCanReadValidations) {
        headerKeys.push('validation')
        if (this.columnSettings.rates) headerKeys.push('validation_rate')
        if (!this.hideAmountColumns) headerKeys.push('validation_amount')
      }

      // Build json data
      const jsonData = []
      const currencySymbols = []

      this.selectedGroupsOrdered.forEach(group => {
        const currencyCode = LocaleCurrency.getCurrency(group.region.code)
        const currencySymbol = getSymbolFromCurrency(currencyCode)

        // Group row
        this.datasets.forEach(dataset => {
          const row = {
            group: group.name,
            region: group.region.code,
            depth: group.depth,
            widget: 'Total',
            print_button: this.groupActionCount(dataset, group.id, 'print_button'),
            print: this.groupActionCount(dataset, group.id, 'print')
          }

          if (this.columnSettings.rates) row.print_rate = this.groupActionRate(dataset, group.id, 'print', 'print_button')

          if (this.hasStoreActivated) {
            row.store_selection = this.groupActionCount(dataset, group.id, 'store_selection')
            if (this.columnSettings.rates) row.store_selection_rate = this.groupActionRate(dataset, group.id, 'store_selection', 'print')
          }

          row.redirection = this.groupActionCount(dataset, group.id, 'redirection')
          if (this.columnSettings.rates) row.redirection_rate = this.groupActionRate(dataset, group.id, 'redirection', 'print')

          if (this.columnSettings.redirectionProduct) row.redirection_product = this.groupActionCount(dataset, group.id, 'redirection_product')

          if (this.userCanReadEngagementAmount && group.region.code !== 'INTERNATIONAL' && !this.hideAmountColumns) {
            row.redirection_amount = this.groupActionAmount(dataset, group.id, 'redirection_product')
          }

          if (this.userCanReadValidations) {
            row.validation = this.groupActionCount(dataset, group.id, 'validation')
            if (this.columnSettings.rates) row.validation_rate = this.groupActionRate(dataset, group.id, 'validation', 'redirection')
            if (group.region.code !== 'INTERNATIONAL' && !this.hideAmountColumns) row.validation_amount = this.groupActionAmount(dataset, group.id, 'validation_product')
          }

          jsonData.push(row)
          currencySymbols.push(currencySymbol)
        })

        // Group widgets rows
        this.sortWidgets(group.widgets).forEach(widget => {
          this.datasets.forEach(dataset => {
            const row = {
              group: group.name,
              region: group.region.code,
              widget: widget.name,
              print_button: this.widgetActionCount(dataset, widget.id, 'print_button'),
              print: this.widgetActionCount(dataset, widget.id, 'print')
            }

            if (this.columnSettings.rawPackaging) row.rawPackaging = widget.product.productLanguageDatas[0].rawPackaging
            if (this.columnSettings.reference) row.reference = widget.product.reference
            if (this.columnSettings.ean) row.ean = widget.product.ean
            if (this.columnSettings.upc) row.upc = widget.product.upc

            if (this.columnSettings.rates) row.print_rate = this.widgetActionRate(dataset, widget.id, 'print', 'print_button')

            if (this.hasStoreActivated) {
              row.store_selection = this.widgetActionCount(dataset, widget.id, 'store_selection')
              if (this.columnSettings.rates) row.store_selection_rate = this.widgetActionRate(dataset, widget.id, 'store_selection', 'print')
            }

            row.redirection = this.widgetActionCount(dataset, widget.id, 'redirection')
            if (this.columnSettings.rates) row.redirection_rate = this.widgetActionRate(dataset, widget.id, 'redirection', 'print')

            if (this.columnSettings.redirectionProduct) row.redirection_product = this.widgetActionCount(dataset, widget.id, 'redirection_product')

            if (this.userCanReadEngagementAmount && !this.hideAmountColumns) {
              row.redirection_amount = this.widgetActionAmount(dataset, widget.id, 'redirection_product')
            }

            if (this.userCanReadValidations) {
              row.validation = this.widgetActionCount(dataset, widget.id, 'validation')
              if (this.columnSettings.rates) row.validation_rate = this.widgetActionRate(dataset, widget.id, 'validation', 'redirection')
              if (!this.hideAmountColumns) row.validation_amount = this.widgetActionAmount(dataset, widget.id, 'validation_product')
            }

            jsonData.push(row)
            currencySymbols.push(currencySymbol)
          })
        })
      })

      // Build xlsx
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(jsonData, { header: headerKeys })

      // Format headers
      const headerTranslations = {
        group: this.$t('views.stats.tunnel.group'),
        region: this.$t('attributes.group.region'),
        depth: this.$t('attributes.group.depth'),
        widget: this.$t('views.stats.tunnel.widget'),
        rawPackaging: this.$t('attributes.productLanguageData.rawPackaging'),
        reference: this.$t('attributes.product.reference'),
        ean: this.$t('attributes.product.ean'),
        upc: this.$t('attributes.product.upc'),
        print_button: this.$t('shared.eventActions.widget.print_button'),
        print: this.$t('shared.eventActions.widget.print'),
        print_rate: this.$t('shared.eventActions.widget.print_rate'),
        store_selection: this.$t('shared.eventActions.widget.store_selection'),
        store_selection_rate: this.$t('shared.eventActions.widget.store_selection_rate'),
        redirection: this.$t('shared.eventActions.widget.redirection'),
        redirection_rate: this.$t('shared.eventActions.widget.redirection_rate'),
        redirection_product: this.$t('shared.eventActions.widget.redirection_product'),
        redirection_amount: this.$t('shared.eventActions.widget.engaged_revenues'),
        validation: this.$t('shared.eventActions.widget.validation'),
        validation_rate: this.$t('shared.eventActions.widget.validation_rate'),
        validation_amount: this.$t('shared.eventActions.widget.revenue')
      }

      const range = XLSX.utils.decode_range(ws['!ref'])
      for (let C = range.s.c; C <= range.e.c; ++C) {
        ws[XLSX.utils.encode_col(C) + '1'].v = headerTranslations[ws[XLSX.utils.encode_col(C) + '1'].v]
      }

      // Add column formats for percentages/currencies
      let textHeadersLength = 4
      if (this.columnSettings.rawPackaging) textHeadersLength++
      if (this.columnSettings.reference) textHeadersLength++
      if (this.columnSettings.ean) textHeadersLength++
      if (this.columnSettings.upc) textHeadersLength++

      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        // Percentages
        if (this.columnSettings.rates) {
          let c = textHeadersLength + 2
          ws[XLSX.utils.encode_cell({ r: R, c: c })].z = '0%' // print rate

          if (this.hasStoreActivated) {
            c += 2
            ws[XLSX.utils.encode_cell({ r: R, c: c })].z = '0%' // store_selection rate
          }

          c += 2
          ws[XLSX.utils.encode_cell({ r: R, c: c })].z = '0%' // redirection rate

          if (this.userCanReadValidations) {
            c += 2
            if (this.columnSettings.redirectionProduct) c++
            if (this.userCanReadEngagementAmount && !this.hideAmountColumns) c++
            ws[XLSX.utils.encode_cell({ r: R, c: c })].z = '0%' // validation rate
          }
        }

        // Currencies
        if (this.userCanReadEngagementAmount || this.userCanReadValidations) {
          const currencySymbol = currencySymbols[R - 1]
          const currencyFormat = `#,##0.00 "${currencySymbol}"`

          let c = textHeadersLength + 3
          if (this.columnSettings.rates) c += 2
          if (this.hasStoreActivated) c++
          if (this.columnSettings.rates && this.hasStoreActivated) c++
          if (this.columnSettings.redirectionProduct) c++

          let cell

          if (this.userCanReadEngagementAmount && !this.hideAmountColumns) {
            cell = ws[XLSX.utils.encode_cell({ r: R, c: c })] // redirection amount
            if (cell) cell.z = currencyFormat
            c++
          }

          c++
          if (this.columnSettings.rates) c++
          cell = ws[XLSX.utils.encode_cell({ r: R, c: c })] // validation amount
          if (cell) cell.z = currencyFormat
        }
      }

      XLSX.utils.book_append_sheet(wb, ws)
      XLSX.writeFile(wb, filename)
    },
    // Compare row class helper
    rowClass: function(dataset) {
      return this.compare ? (dataset === 'compare' ? 'text-orange' : 'text-primary') : ''
    },

    /* Stats */
    // Get hashes for a dataset
    analyticsDataEventsHash: function(dataset) {
      return dataset === 'main' ? this.analyticsDataEventsMainHash : this.analyticsDataEventsCompareHash
    },
    // Count for a widget and action
    widgetActionCount: function(dataset, id, action) {
      const hash = this.analyticsDataEventsHash(dataset)
      return (hash[action] && hash[action][id]) ? hash[action][id].count : 0
    },
    // Amount for a widget and action
    widgetActionAmount: function(dataset, id, action) {
      const hash = this.analyticsDataEventsHash(dataset)
      return (hash[action] && hash[action][id]) ? hash[action][id].amount : null
    },
    // Count for a group and action
    groupActionCount: function(dataset, id, action) {
      var groupActionCount = 0
      this.groupWidgetsMap.get(id).forEach(widget => {
        groupActionCount += this.widgetActionCount(dataset, widget.id, action)
      })
      return groupActionCount
    },
    // Amount for a group and action
    groupActionAmount: function(dataset, id, action) {
      var groupActionAmount = 0
      this.groupWidgetsMap.get(id).forEach(widget => { groupActionAmount += this.widgetActionAmount(dataset, widget.id, action) || 0 })
      return groupActionAmount
    },
    // Count for an action
    actionCount: function(dataset, action) {
      var hash = this.analyticsDataEventsHash(dataset)
      var actionCount = 0

      if (hash[action]) {
        actionCount = this.selectedWidgetIds.reduce((acc, widgetId) => hash[action][widgetId] ? acc + hash[action][widgetId].count : acc, 0)
      }

      return actionCount
    },
    // Ratio for a widget for 2 actions
    widgetActionRate: function(dataset, id, action1, action2) {
      var count1 = this.widgetActionCount(dataset, id, action1)
      var count2 = this.widgetActionCount(dataset, id, action2)
      var ratio
      if (count1 > 0 && count2 > 0) {
        ratio = count1 / count2
      }

      return ratio
    },
    // Ratio for a group for 2 actions
    groupActionRate: function(dataset, id, action1, action2) {
      var count1 = this.groupActionCount(dataset, id, action1)
      var count2 = this.groupActionCount(dataset, id, action2)
      var ratio
      if (count1 > 0 && count2 > 0) {
        ratio = count1 / count2
      }

      return ratio
    },
    // Ratio for 2 actions
    actionRate: function(dataset, action1, action2) {
      var count1 = this.actionCount(dataset, action1)
      var count2 = this.actionCount(dataset, action2)
      var ratio
      if (count1 > 0 && count2 > 0) {
        ratio = count1 / count2
      }

      return ratio
    },
    // Widget data
    widgetData: function(key, dataset, widget) {
      let widgetData

      switch (key) {
        case 'printButton':
          widgetData = this.widgetActionCount(dataset, widget.id, 'print_button')
          break
        case 'print':
          widgetData = this.widgetActionCount(dataset, widget.id, 'print')
          break
        case 'printRate':
          widgetData = this.widgetActionRate(dataset, widget.id, 'print', 'print_button')
          break
        case 'storeSelection':
          widgetData = this.widgetActionCount(dataset, widget.id, 'store_selection')
          break
        case 'storeSelectionRate':
          widgetData = this.widgetActionRate(dataset, widget.id, 'store_selection', 'print')
          break
        case 'redirection':
          widgetData = this.widgetActionCount(dataset, widget.id, 'redirection')
          break
        case 'redirectionProduct':
          widgetData = this.widgetActionCount(dataset, widget.id, 'redirection_product')
          break
        case 'redirectionRate':
          widgetData = this.widgetActionRate(dataset, widget.id, 'redirection', 'print')
          break
        case 'validation':
          widgetData = this.widgetActionCount(dataset, widget.id, 'validation')
          break
        case 'validationRate':
          widgetData = this.widgetActionRate(dataset, widget.id, 'validation', 'redirection')
          break
        case 'revenue':
          widgetData = this.widgetActionAmount(dataset, widget.id, 'validation_product')
          break
        case 'engagedRevenue':
          widgetData = this.widgetActionAmount(dataset, widget.id, 'redirection_product')
          break
      }

      return widgetData
    },
    // Time series for an action
    actionTimeSerie: function(dataset, action) {
      return this.eventAggregatesDaily[dataset].map(eventAggregate => {
        return [
          Date.UTC(eventAggregate._id.year, eventAggregate._id.month - 1, eventAggregate._id.day),
          eventAggregate.data.filter(item => item.action === action && this.selectedGroupIdsSet.has(item.groupId)).reduce((acc, item) => acc + item.count, 0)
        ]
      }).sort((a, b) => a[0] - b[0])
    },
    // Variation between 2 numbers
    variation: function(a, b) {
      let variation

      if (a === 0) {
        if (b === 0) {
          variation = 0
        } else {
          variation = -1
        }
      } else {
        variation = (a - b) / b
      }

      return variation
    },
    variationClass: function(variation) {
      if (variation < 0) return 'text-danger'
      else if (variation > 0) return 'text-success'
      else return 'text-secondary'
    },
    // Set sort & direction
    setSort: function(key) {
      if (this.sortKey !== key) {
        this.sortKey = key
        this.sortDirection = 'asc'
      } else if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    // aria-sort value for table th
    ariaSort: function(key) {
      if (this.sortKey !== key) {
        return 'none'
      } else if (this.sortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    },
    // Sort widget list
    sortWidgets: function(widgets) {
      var sortedWidgets = [...widgets]

      if (this.sortKey) {
        sortedWidgets.sort((widget1, widget2) => {
          const val1 = this.widgetData(this.sortKey, 'main', widget1) || 0
          const val2 = this.widgetData(this.sortKey, 'main', widget2) || 0
          const sortDirectionFactor = this.sortDirection === 'asc' ? 1 : -1
          return sortDirectionFactor * (val1 - val2)
        })
      } else {
        sortedWidgets.sort((widget1, widget2) => widget1.id - widget2.id)
      }

      return sortedWidgets
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    percentage: function(value) {
      return value ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '–'
    },
    variation: function(value) {
      if (value === 0) {
        return '='
      } else {
        const percentage = value.toLocaleString(i18n.locale, {
          style: 'percent',
          maximumFractionDigits: 2
        })
        return `${value > 0 ? '+' : ''}${percentage}`
      }
    },
    price: function(value, region) {
      const currency = LocaleCurrency.getCurrency(region.code)
      if (value !== null && value > 0 && currency) {
        return value.toLocaleString(i18n.locale, {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2
        })
      } else {
        return '–'
      }
    },
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    }
  },
  watch: {
    // Load analytics data when date range changes
    'dateRanges.main': async function(dateRange) {
      await Promise.all([
        this.loadEventAggregates('main'),
        this.loadEventAggregatesDaily('main')
      ])
    },
    // Load analytics data when date range changes
    'dateRanges.compare': async function(dateRange) {
      await Promise.all([
        this.loadEventAggregates('compare'),
        this.loadEventAggregatesDaily('compare')
      ])
    }
  },
  created: function() {
    this.loadGroups()
  }
}
</script>
