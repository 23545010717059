<template>
  <div>
    <!-- Alert for unauthorized users -->
    <div
      v-if="eventAggregatesError && eventAggregatesError.status === 403"
      class="alert alert-danger"
    >
      {{ $t('errors.unauthorized.manage.all') }}
    </div>

    <!-- Temporary message for amount columns issue -->
    <div v-if="hideAmountColumns" class="alert alert-warning">
      <div>{{ $t('shared.warnings.amountsHidden1') }}</div>
      <div>{{ $t('shared.warnings.amountsHidden2') }}</div>
      <div>{{ $t('shared.warnings.amountsHidden3') }}</div>
    </div>

    <!-- First row with group selector & date range picker -->
    <div class="mb-3 d-flex flex-sm-row flex-column gutter">
      <!-- Group tree select -->
      <div class="flex-fill flex-basis-0">
        <treeselect
          :limit-text="treeSelectLimitText"
          :limit="0"
          :multiple="true"
          :options="groupsTreeSelect"
          :searchable="false"
          style="max-width: 275px;"
          v-model="selectedGroupIds"
          value-consists-of="ALL_WITH_INDETERMINATE"
          :placeholder="groupsLoading ? 'Loading...' : 'Select...'">
          <template slot="option-label" slot-scope="{ node }">
            <region-flag v-if="international" :code="node.label.regionCode" />
            {{ node.label.name }}
          </template>
        </treeselect>
      </div>

      <!-- Date range pickers -->
      <div class="flex-fill flex-basis-0 text-center">
        <stats-date-range-picker v-model="dateRange" />
      </div>

      <!-- Empty right col -->
      <div class="flex-fill flex-basis-0 text-right">
        <button @click="exportXLSX" type="button" class="btn btn-primary">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12"><path fill="currentColor" d="M296 368h-48v48h48v-48zm-80-80h-48v48h48v-48zm80 0h-48v48h48v-48zm-80 80h-48v48h48v-48zm8-232V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm104 104v192c0 8.84-7.16 16-16 16H72c-8.84 0-16-7.16-16-16V240c0-8.84 7.16-16 16-16h240c8.84 0 16 7.16 16 16zm49-135L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zM136 288H88v48h48v-48zm0 80H88v48h48v-48z" class=""></path></svg>
          {{ $t('shared.actions.xlsxExport') }}
        </button>
      </div>
    </div>

    <!-- Pie charts -->
    <div class="card mb-3">
      <div class="card-body">
        <div class="position-relative">
          <div
            v-if="groupsLoading || eventAggregatesLoading"
            class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
            style="z-index: 1;">
            <md-spinner md-indeterminate />
          </div>
          <div class="d-flex gutter">
            <div class="flex-fill flex-basis-0">
              <highcharts
                :options="chartOptions.print"
                class="chart"
                :aria-busy="eventAggregatesLoading"
                style="width: 100%; height: 300px;" />
              <div class="text-center">
                <b>{{ $t('shared.eventActions.widget.print') }}</b>
              </div>
            </div>
            <div class="flex-fill flex-basis-0">
              <highcharts
                :options="chartOptions.redirection"
                class="chart"
                :aria-busy="eventAggregatesLoading"
                style="width: 100%; height: 300px;" />
              <div class="text-center">
                <b>{{ $t('shared.eventActions.widget.redirection') }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-vertical-align-top" id="table-stats-parent-sites">
        <thead>
          <tr>
            <th class="cell-lg">{{ $t('views.stats.parentSites.parentHostname') | capitalize }}</th>
            <th class="data-cell">{{ $t('shared.eventActions.widget.print_button') }}</th>
            <th class="data-cell">{{ $t('shared.eventActions.widget.print') }}</th>
            <th class="data-cell" v-if="hasStoreActivated">{{ $t('shared.eventActions.widget.store_selection') | capitalize }}</th>
            <th class="data-cell">{{ $t('shared.eventActions.widget.redirection') }}</th>
            <th class="data-cell">{{ $t('shared.eventActions.widget.engagement_rate') | capitalize }}</th>
            <template v-if="(userIsAdmin || userIsMichelin) && !hideAmountColumns">
              <th class="data-cell th-shield" v-if="!international">
                {{ $t('shared.eventActions.widget.engaged_revenues') | capitalize }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
            <template v-if="userCanReadValidations">
              <th class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.validation') }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th class="data-cell th-shield">
                {{ $t('shared.eventActions.widget.validation_rate') | capitalize }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
              <th class="data-cell th-shield" v-if="!international && !hideAmountColumns">
                {{ $t('shared.eventActions.widget.revenue') | capitalize }}
                <div v-if="userIsAdmin" class="shield-wrapper text-warning">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="groupsLoading || eventAggregatesLoading">
            <tr>
              <td :colspan="colspan" class="text-center">
                <md-spinner md-indeterminate />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="font-weight-semibold bg-light" v-if="tableRows.length > 1">
              <td>Total</td>
              <td class="text-right">{{ tableData.totals.print_button.count | number }}</td>
              <td class="text-right">{{ tableData.totals.print.count | number }}</td>
              <td class="text-right" v-if="hasStoreActivated">{{ tableData.totals.store_selection.count | number }}</td>
              <td class="text-right">{{ tableData.totals.redirection.count | number }}</td>
              <td class="text-right">{{ tableData.totals.redirection_rate | percentage }}</td>
              <template v-if="(userIsAdmin || userIsMichelin) && !hideAmountColumns">
                <td class="text-right" v-if="!international">{{ tableData.totals.redirection_product.amount | price(group.region) }}</td>
              </template>
              <template v-if="userCanReadValidations">
                <td class="text-right">{{ tableData.totals.validation.count | number }}</td>
                <td class="text-right">{{ tableData.totals.validation_rate | percentage }}</td>
                <td class="text-right" v-if="!international && !hideAmountColumns">{{ tableData.totals.validation_product.amount | price(group.region) }}</td>
              </template>
            </tr>
            <template v-for="row in tableRows">
              <tr :key="row.parentHostname" :class="{ 'bg-light': row.sourceGroup }">
                <td v-if="row.sourceGroup" class="cursor-pointer" @click="toggleSourceGroup(row.sourceGroup.id)">
                  <div class="d-flex gutter-sm justify-content-between align-items-center">
                    <div>{{ row.sourceGroup.name }}</div>
                    <svg v-if="!showSourceGroups[row.sourceGroup.id]" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
                    <svg v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-up fa-w-10"><path fill="currentColor" d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" class=""></path></svg>
                  </div>
                </td>
                <td v-else>
                  <svg v-if="row.sourceGroupId" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-caret-right fa-w-6"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" class=""></path></svg>
                  <code v-if="row.parentHostname === null">{{ String(row.parentHostname) }}</code>
                  <template v-else>{{ row.parentHostname }}</template>
                </td>
                <td class="text-right">{{ row.print_button.count | number }}</td>
                <td class="text-right">{{ row.print.count | number }}</td>
                <td class="text-right" v-if="hasStoreActivated">{{ row.store_selection.count | number }}</td>
                <td class="text-right">{{ row.redirection.count | number }}</td>
                <td class="text-right">{{ row.redirection_rate | percentage }}</td>
                <template v-if="(userIsAdmin || userIsMichelin) && !hideAmountColumns">
                  <td class="text-right" v-if="!international">{{ row.redirection_product.amount | price(group.region) }}</td>
                </template>
                <template v-if="userCanReadValidations">
                  <td class="text-right">{{ row.validation.count | number }}</td>
                  <td class="text-right">{{ row.validation_rate | percentage }}</td>
                  <td class="text-right" v-if="!international && !hideAmountColumns">{{ row.validation_product.amount | price(group.region) }}</td>
                </template>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Source groups list -->
    <template v-if="userIsAdmin">
      <div class="d-flex align-items-end justify-content-between mb-3">
        <h4 class="mb-0">
          {{ $t('views.stats.parentSites.sourceGroups') }}
          <sup class="text-warning">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-alt fa-w-16"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" class=""></path></svg>
          </sup>
        </h4>
        <button type="button" class="btn btn-success" @click="newSourceGroup()">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
          {{ $t('shared.actions.newSourceGroup') }}
        </button>
      </div>

      <template v-if="sourceGroups.length > 0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t('attributes.sourceGroup.name') }}</th>
              <th>{{ $t('attributes.sourceGroup.sources') }}</th>
              <th class="shrink"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sourceGroup in sourceGroups" :key="sourceGroup.id">
              <td>{{ sourceGroup.name }}</td>
              <td>
                <template v-if="sourceGroup.sources">
                  <div class="d-flex flex-wrap gutter-xs">
                    <span v-for="source in sourceGroup.sources" :key="source" class="badge badge-primary">{{ source }}</span>
                  </div>
                </template>
              </td>
              <td class="text-nowrap">
                <div class="gutter-x-xs">
                  <button type="button" class="btn btn-sm btn-warning" @click="editSourceGroup(sourceGroup)">
                    {{ $t('shared.actions.edit') }}
                  </button>
                  <button type="button" @click="deleteSourceGroup(sourceGroup)" class="btn btn-sm btn-danger">
                    {{ $t('shared.actions.delete') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <div class="alert alert-warning">
          {{ $t('shared.warnings.noSourceGroup') }}
        </div>
      </template>
    </template>

    <!-- Source group modal (form) -->
    <b-modal
      id="sourceGroupModal"
      ref="sourceGroupModal"
      size="lg"
      :title="$t(`shared.actions.${sourceGroupModalAction}SourceGroup`)"
      @ok="sourceGroupModalOk"
      @shown="$refs.sourceGroupModalNameInput.focus()"
      no-fade>
      <form @submit.stop.prevent="sourceGroupModalSubmit()">
        <div class="form-group row">
          <label for="name-input" class="col-sm-2 col-form-label">{{ $t('attributes.sourceGroup.name') }}</label>
          <div class="col-lg-6 col-sm-8 col-12">
            <input
              v-model="sourceGroupModal.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.mail }"
              id="name-input"
              :placeholder="$t('attributes.sourceGroup.name')"
              ref="sourceGroupModalNameInput">
            <div v-if="sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.name" class="invalid-feedback">{{ tErrors('sourceGroup', 'name', sourceGroupModalSubmitErrors.name) }}</div>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label for="sources-multiselect" class="col-sm-2 col-form-label">{{ $t('attributes.sourceGroup.sources') }}</label>
          <div class="col-lg-6 col-sm-8 col-12">
            <multiselect
              :multiple="true"
              v-model="sourceGroupModal.sources"
              :options="parentHostnamesWithoutSourceGroup"
              :close-on-select="false"
              open-direction="bottom"
              :allow-empty="false"
              :placeholder="$t('shared.placeholders.select')">
            </multiselect>
            <div v-if="sourceGroupModalSubmitErrors && sourceGroupModalSubmitErrors.name" class="invalid-feedback">{{ tErrors('sourceGroup', 'name', sourceGroupModalSubmitErrors.name) }}</div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MdSpinner from '../../shared/MdSpinner.vue'
import RegionFlag from '../../shared/RegionFlag.vue'
import StatsDateRangePicker from '../../shared/StatsDateRangePicker.vue'
import Treeselect from '@riophae/vue-treeselect'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import { BModal, VBModal } from 'bootstrap-vue'
import LocaleCurrency from 'locale-currency'
import arrayToTree from 'array-to-tree'
import snakeCase from 'lodash-es/snakeCase'
import moment from 'moment'
import * as XLSX from 'xlsx'
import i18n from '../../../i18n'

export default {
  components: { MdSpinner, RegionFlag, StatsDateRangePicker, Treeselect, Multiselect, highcharts: Chart, BModal },
  directives: { 'b-modal': VBModal },
  data: function() {
    return {
      groups: [],
      groupsLoading: false,
      groupsError: null,
      sourceGroups: [],
      eventAggregates: [],
      eventAggregatesLoading: false,
      eventAggregatesError: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedGroupIds: [],
      showSourceGroups: {},
      sourceGroupModalAction: 'new',
      sourceGroupModal: {
        id: null,
        name: null,
        sources: []
      },
      sourceGroupModalRef: null,
      sourceGroupModalSubmitLoading: false,
      sourceGroupModalSubmitErrors: null,
      parentHostnameColors: new Map(), // Parent hostname colors for pie charts
      colorSeq: 0 // Color sequence to assign new colors
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles'
    }),
    group: function() {
      return this.groups ? this.groups.find(group => group.id === parseInt(this.$route.params.groupId)) : null
    },
    // User helpers
    userIsAdmin: function() {
      return this.roles.includes('admin')
    },
    userCanReadValidations: function() {
      return this.roles.includes('admin') || this.roles.includes('validations_reader')
    },
    userIsMichelin: function() {
      return this.roles.includes('michelin')
    },
    hasStoreActivated: function() {
      return this.group ? this.group.hasStoreActivated : null
    },
    // Hide amount columns for non-admin users if dateRange intersects with 2025-01-04 - 2025-01-09
    hideAmountColumns: function() {
      const userCanReadEngagementAmount = this.userIsAdmin || this.userIsMichelin
      const wasAccessingAmountColumns = userCanReadEngagementAmount || this.userCanReadValidations
      return !this.userIsAdmin && wasAccessingAmountColumns && (this.dateRange.startDate <= new Date('2025-01-10') && this.dateRange.endDate >= new Date('2025-01-04'))
    },
    colspan: function() {
      let tableColsLength = 5

      if (this.hasStoreActivated) {
        tableColsLength += 1
      }

      if ((this.userIsAdmin || this.userIsMichelin) && !this.international) {
        tableColsLength += 1
      }

      if (this.userCanReadValidations) {
        tableColsLength += 2

        if (!this.international) {
          tableColsLength += 1
        }
      }

      return tableColsLength
    },
    // International group
    international: function() {
      return this.group ? this.group.region.code === 'INTERNATIONAL' : null
    },
    // Group helpers
    groupsTree: function() {
      let groupsTree = {}

      if (this.groups.length > 0) {
        groupsTree = arrayToTree(this.groups, { parentProperty: 'parentId' })[0]
      }

      return groupsTree
    },
    groupIds: function() {
      return this.groups.map(group => group.id)
    },
    // Format group tree for vue treeselect component
    groupsTreeSelect: function() {
      let groupsTreeSelect = []

      if (this.groups.length > 0) {
        groupsTreeSelect = arrayToTree(this.groups.map(group => {
          return {
            id: group.id,
            label: {
              name: group.name,
              regionCode: group.region.code
            },
            parent_id: group.parentId
          }
        }))
      }

      return groupsTreeSelect
    },
    // Convert daily event stats into total values as a hash
    eventAggregatesMap: function() {
      const eventAggregatesMap = new Map()

      this.eventAggregates.forEach(item => {
        let parentHostnameMap
        if (!eventAggregatesMap.has(item._id.parentHostname)) {
          parentHostnameMap = new Map()
          eventAggregatesMap.set(item._id.parentHostname, parentHostnameMap)
        } else {
          parentHostnameMap = eventAggregatesMap.get(item._id.parentHostname)
        }

        let actionMap
        if (!parentHostnameMap.has(item._id.action)) {
          actionMap = new Map()
          parentHostnameMap.set(item._id.action, actionMap)
        } else {
          actionMap = parentHostnameMap.get(item._id.action)
        }

        if (!actionMap.has(item._id.groupId)) {
          const groupItem = {
            count: item.count,
            amount: item.amount
          }

          actionMap.set(item._id.groupId, groupItem)
        } else {
          const groupItem = actionMap.get(item._id.groupId)

          groupItem.count += item.count
          groupItem.amount += item.amount

          actionMap.set(item._id.groupId, groupItem)
        }
      })

      return eventAggregatesMap
    },
    // parentHostname/sourceGroup/total stats
    tableData: function() {
      const totals = {}
      const sourceGroupsMap = new Map()
      const parentHostnamesMap = new Map()

      const actions = ['print_button', 'print', 'store_selection', 'redirection', 'redirection_product', 'validation', 'validation_product']

      actions.forEach(action => {
        totals[action] = {
          count: 0,
          amount: 0
        }
      })

      for (const [parentHostname, parentHostnameMap] of this.eventAggregatesMap) {
        let hasData = false

        // Compute parentHostname stats (sum selected widgets)
        const parentHostnameItem = {}

        actions.forEach(action => {
          parentHostnameItem[action] = {
            count: 0,
            amount: 0
          }

          if (parentHostnameMap.has(action)) {
            const actionMap = parentHostnameMap.get(action)

            this.selectedGroupIds.forEach(groupId => {
              if (actionMap.has(groupId)) {
                hasData = true
                parentHostnameItem[action].count += actionMap.get(groupId).count
                parentHostnameItem[action].amount += actionMap.get(groupId).amount
              }
            })
          }
        })

        if (hasData) {
          // Redirection rate
          if (parentHostnameItem.print.count > 0) {
            parentHostnameItem.redirection_rate = parentHostnameItem.redirection.count / parentHostnameItem.print.count
          }

          // Validation rate
          if (parentHostnameItem.redirection.count > 0) {
            parentHostnameItem.validation_rate = parentHostnameItem.validation.count / parentHostnameItem.redirection.count
          }

          parentHostnamesMap.set(parentHostname, parentHostnameItem)

          // Source group
          const sourceGroup = this.sourceGroups.find(sourceGroup => sourceGroup.sources.includes(parentHostname))
          if (sourceGroup) {
            let sourceGroupItem
            if (!sourceGroupsMap.has(sourceGroup.id)) {
              sourceGroupItem = {}
              actions.forEach(action => {
                sourceGroupItem[action] = {
                  count: 0,
                  amount: 0
                }
              })
            } else {
              sourceGroupItem = sourceGroupsMap.get(sourceGroup.id)
            }

            actions.forEach(action => {
              sourceGroupItem[action].count += parentHostnameItem[action].count
              sourceGroupItem[action].amount += parentHostnameItem[action].amount
            })

            // Source group redirection rate
            if (sourceGroupItem.print.count > 0) {
              sourceGroupItem.redirection_rate = sourceGroupItem.redirection.count / sourceGroupItem.print.count
            }

            // Source group validation rate
            if (sourceGroupItem.redirection.count > 0) {
              sourceGroupItem.validation_rate = sourceGroupItem.validation.count / sourceGroupItem.redirection.count
            }

            sourceGroupsMap.set(sourceGroup.id, sourceGroupItem)
          }

          // Totals
          actions.forEach(action => {
            totals[action].count += parentHostnameItem[action].count
            totals[action].amount += parentHostnameItem[action].amount
          })

          // Total redirection rate
          if (totals.print.count > 0) {
            totals.redirection_rate = totals.redirection.count / totals.print.count
          }

          // Total validation rate
          if (totals.redirection.count > 0) {
            totals.validation_rate = totals.validation.count / totals.redirection.count
          }
        }
      }

      return {
        parentHostnamesMap,
        sourceGroupsMap,
        totals
      }
    },
    // parentHostnames/sourceGroups stats in a list
    parentHostnamesAndSourceGroupsData: function() {
      const parentHostnamesWithoutSourceGroup = Array.from(this.tableData.parentHostnamesMap)
        .map(([key, value]) => {
          return {
            parentHostname: key,
            ...value
          }
        })
        .filter(parentHostnameItem => {
          return !this.sourceGroups.find(sourceGroup => sourceGroup.sources.includes(parentHostnameItem.parentHostname))
        })

      // Source groups
      const sourceGroups = Array.from(this.tableData.sourceGroupsMap)
        .map(([key, value]) => {
          return {
            sourceGroupId: key,
            sourceGroup: this.sourceGroups.find(sourceGroup => sourceGroup.id === key),
            ...value
          }
        })

      return parentHostnamesWithoutSourceGroup.concat(sourceGroups).sort(this.sortFunction)
    },
    // Table rows
    tableRows: function() {
      const tableRows = []

      // parentHostnames/sourceGroups + sourceGroup children showing
      this.parentHostnamesAndSourceGroupsData.forEach(item => {
        tableRows.push(item)
        if (item.sourceGroup && this.showSourceGroups[item.sourceGroup.id]) {
          const sourceGroupRows = []

          item.sourceGroup.sources.forEach(source => {
            if (this.tableData.parentHostnamesMap.has(source)) {
              sourceGroupRows.push({
                parentHostname: source,
                sourceGroupId: item.sourceGroup.id,
                ...this.tableData.parentHostnamesMap.get(source)
              })
            }
          })
          sourceGroupRows.sort(this.sortFunction)

          tableRows.push(...sourceGroupRows)
        }
      })

      return tableRows
    },
    // Charts
    chartOptions: function() {
      const chartOptions = {}
      const percentageFilter = this.$options.filters.percentage
      const actions = ['print', 'redirection']

      actions.forEach(action => {
        chartOptions[action] = {
          chart: { type: 'pie' },
          title: null,
          credits: { enabled: false },
          series: [{
            name: this.$t(`shared.eventActions.widget.${action}`),
            data: this.parentHostnamesAndSourceGroupsData.map(row => {
              const name = row.parentHostname ? row.parentHostname : row.sourceGroup ? row.sourceGroup.name : 'undefined'

              // Add color to map if not already set
              if (!this.parentHostnameColors.has(name)) {
                this.parentHostnameColors.set(name, Highcharts.getOptions().colors[this.colorSeq])

                this.colorSeq++
                if (this.colorSeq === Highcharts.getOptions().colors.length) {
                  this.colorSeq = 0
                }
              }

              return {
                name: name,
                y: row[action].count,
                color: this.parentHostnameColors.get(name)
              }
            }).sort((a, b) => (a.y < b.y) ? 1 : ((b.y < a.y) ? -1 : 0))
          }],
          plotOptions: {
            pie: {
              size: '75%',
              dataLabels: {
                formatter: function() {
                  if (this.percentage > 2) {
                    return `${this.point.name} - ${percentageFilter(this.percentage / 100)}`
                  }
                },
                useHTML: true
              }
            }
          }
        }
      })

      return chartOptions
    },
    // Parent hostnames
    parentHostnames: function() {
      return Array.from(this.tableData.parentHostnamesMap.keys())
    },
    // Parent hostnames without a source group
    parentHostnamesWithoutSourceGroup: function() {
      return this.parentHostnames.filter(parentHostname => !this.sourceGroups.some(sourceGroup => sourceGroup.sources.includes(parentHostname)))
    }
  },
  methods: {
    // Serialize Date to string for URLs
    dateSerializer: function(date) {
      return moment.utc()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .format('YYYY-MM-DD')
    },
    // Treeselect text limit formatter
    treeSelectLimitText: function(count) {
      return this.$tc('shared.treeSelect.limitText.groups', count)
    },
    // Load data
    loadGroups: async function() {
      this.groupsLoading = true
      this.groupsError = null

      const query = `query statsSourcesGroups($id: Int!) {
        groups(id: $id) {
          id
          parentId
          name
          hasStoreActivated
          region {
            id
            code
          }
          sourceGroups {
            id
            name
            sources
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: { id: parseInt(this.$route.params.groupId) }
          })
        })

        const data = await res.json()

        this.groupsLoading = false
        if (!res.ok) {
          this.groupsError = { status: res.status, data }
        } else {
          this.groups = Object.freeze(data.data.groups)
          this.sourceGroups = this.groups.map(group => group.sourceGroups).flat()

          // Init group treeselect value
          this.selectedGroupIds = this.groups.map(group => group.id)
        }
      } catch (err) {
        this.groupsError = err
      } finally {
        this.groupsLoading = false
      }
    },
    // Load daily event aggregates
    loadEventAggregates: async function() {
      this.eventAggregatesLoading = true
      this.eventAggregatesError = null

      const baseBody = {
        mediums: ['widget'],
        start_date: moment.utc(this.dateRange.startDate).startOf('day').format('x'),
        end_date: moment.utc(this.dateRange.endDate).endOf('day').format('x'),
        interval: 'total',
        group_keys: ['action', 'groupId', 'parentHostname'],
        group_id: this.$route.params.groupId
      }

      const loadClassicActions = async function() {
        const body = {
          ...baseBody,
          actions: ['print_button', 'print', 'redirection', 'redirection_product', 'validation', 'validation_product']
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      const loadStoreSelections = async function() {
        const body = {
          ...baseBody,
          actions: ['retail_outlet_selection'],
          store: true
        }

        const res = await fetch('/api/interface/stats/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
        if (!res.ok) {
          throw res
        } else {
          const json = await res.json()
          return json
        }
      }

      try {
        const [data, storeSelectionData] = await Promise.all([loadClassicActions(), loadStoreSelections()])

        storeSelectionData.forEach(item => {
          item._id.action = 'store_selection'
        })

        this.eventAggregates = Object.freeze(data.concat(storeSelectionData))
      } catch (err) {
        this.eventAggregatesError = err
      } finally {
        this.eventAggregatesLoading = false
      }
    },
    // Export table as XLSX
    exportXLSX: function() {
      const filename = `${snakeCase(this.group.name)}_${this.group.region.code.toLowerCase()}_parentSites_${this.dateSerializer(this.dateRange.startDate)}_${this.dateSerializer(this.dateRange.endDate)}.xlsx`
      const wb = XLSX.utils.table_to_book(document.querySelector('#table-stats-parent-sites'))
      XLSX.writeFile(wb, filename)
    },
    // Toggle sourceGroup
    toggleSourceGroup: function(id) {
      this.$set(this.showSourceGroups, id, this.showSourceGroups[id] ? !this.showSourceGroups[id] : true)
    },
    // Sort function for table/pie chart
    sortFunction: (a, b) => b.print_button.count - a.print_button.count,
    /* SourceGroup modal */
    // New sourceGroup
    newSourceGroup: function() {
      this.sourceGroupModalAction = 'new'
      this.sourceGroupModal = {
        name: null,
        sources: []
      }
      this.$refs.sourceGroupModal.show()
    },
    // Edit sourceGroup
    editSourceGroup: function(sourceGroup) {
      this.sourceGroupModalAction = 'edit'
      this.sourceGroupModal = { ...sourceGroup }
      this.sourceGroupModalRef = sourceGroup
      this.$refs.sourceGroupModal.show()
    },
    // Delete sourceGroup
    deleteSourceGroup: function(sourceGroup) {
      if (confirm(this.$t('shared.confirm.delete'))) {
        const variables = {
          input: {
            sourceGroupId: sourceGroup.id
          }
        }

        const mutation = `mutation($input: DestroySourceGroupInput!) {
          destroySourceGroup(input: $input) {
            sourceGroup {
              id
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            if (data.data.destroySourceGroup.errors) {
              const text = data.data.destroySourceGroup.errors
              this.$root.$bvToast.toast(text, {
                variant: 'danger',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            } else {
              this.sourceGroups = this.sourceGroups.filter(oldSourceGroup => oldSourceGroup.id !== sourceGroup.id)

              this.$root.$bvToast.toast(this.$t('shared.success.sourceGroup.delete'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    },
    // Modal ok click
    sourceGroupModalOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.sourceGroupModalSubmit()
    },
    // Modal form submit
    sourceGroupModalSubmit() {
      this.sourceGroupModalSubmitLoading = true
      this.sourceGroupModalSubmitErrors = null

      if (this.sourceGroupModalAction === 'new') {
        // Create sourceGroup
        const variables = {
          input: {
            attributes: {
              groupId: this.$route.params.groupId,
              name: this.sourceGroupModal.name,
              sources: this.sourceGroupModal.sources
            }
          }
        }

        const mutation = `mutation($input: CreateSourceGroupInput!) {
          createSourceGroup(input: $input) {
            sourceGroup {
              id
              name
              sources
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.sourceGroupModalSubmitLoading = false

            if (data.data.createSourceGroup.errors) {
              this.sourceGroupModalSubmitErrors = data.data.createSourceGroup.errors
            } else {
              this.sourceGroups.push(data.data.createSourceGroup.sourceGroup)
              this.$refs.sourceGroupModal.hide()
              this.$root.$bvToast.toast(this.$t('shared.success.sourceGroup.create'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      } else {
        // Update sourceGroup
        const variables = {
          input: {
            sourceGroupId: this.sourceGroupModal.id,
            attributes: {
              name: this.sourceGroupModal.name,
              sources: this.sourceGroupModal.sources
            }
          }
        }

        const mutation = `mutation($input: UpdateSourceGroupInput!) {
          updateSourceGroup(input: $input) {
            sourceGroup {
              id
              name
              sources
            }
            errors
          }
        }`

        return fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query: mutation, variables })
        })
          .then(res => {
            return new Promise(resolve => {
              res.json().then(data => {
                resolve({ res, data })
              }).catch(() => {
                resolve({ res })
              })
            })
          }).then(({ res, data }) => {
            this.sourceGroupModalSubmitLoading = false

            if (data.data.updateSourceGroup.errors) {
              this.sourceGroupModalSubmitErrors = data.data.updateSourceGroup.errors
            } else {
              const sourceGroupIndex = this.sourceGroups.findIndex(sourceGroup => sourceGroup.id === data.data.updateSourceGroup.sourceGroup.id)
              this.$set(this.sourceGroups, sourceGroupIndex, data.data.updateSourceGroup.sourceGroup)
              this.$refs.sourceGroupModal.hide()
              this.$root.$bvToast.toast(this.$t('shared.success.sourceGroup.update'), {
                variant: 'success',
                noCloseButton: true,
                autoHideDelay: 3000
              })
            }
          })
      }
    }
  },
  filters: {
    number: function(value) {
      return value.toLocaleString()
    },
    percentage: function(value) {
      return value !== undefined ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '-'
    },
    price: function(value, region) {
      const currency = LocaleCurrency.getCurrency(region.code)
      if (value !== undefined && currency) {
        return value.toLocaleString(i18n.locale, {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 2
        })
      } else {
        return '-'
      }
    },
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    }
  },
  watch: {
    // Load analytics data when date range changes
    dateRange: function() {
      this.loadEventAggregates()
    }
  },
  created: function() {
    this.loadGroups()
  }
}
</script>
