
<div>
  <transition name="fade" mode="out-in">
    <div v-if="michelinWidgetCustomisationLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="michelinWidgetCustomisationError" class="alert alert-danger">
      <template v-if="michelinWidgetCustomisationError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <b-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.michelinWidgetCustomisations.tabs.infos')" active>
            <div class="form-group row">
              <label for="input-name" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.name') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input
                  v-model="michelinWidgetCustomisation.name"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid' : michelinWidgetCustomisationSubmitErrors && michelinWidgetCustomisationSubmitErrors.name }"
                  id="input-name"
                  :placeholder="$t('attributes.michelinWidgetCustomisation.name')">
                <div v-if="michelinWidgetCustomisationSubmitErrors && michelinWidgetCustomisationSubmitErrors.name" class="invalid-feedback">{{ tErrors('michelinWidgetCustomisation', 'name', michelinWidgetCustomisationSubmitErrors.name) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-groups" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.group') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="michelinWidgetCustomisation.groupId"
                  :options="groups"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="name"
                  track-by="id"
                  :placeholder="$t('shared.placeholders.select')"
                  :show-labels="false"
                  :loading="groupsLoading"
                  :class="{ 'is-invalid' : michelinWidgetCustomisationSubmitErrors && michelinWidgetCustomisationSubmitErrors.group }"
                  id="multiselect-groups">
                  <template v-slot:singleLabel="{ option }">
                    <region-flag :code="option.region.code" />
                    {{ option.name }}
                  </template>
                  <template v-slot:option="{ option }">
                    <region-flag :code="option.region.code" />
                    {{ option.name }}
                  </template>
                </key-multiselect>
                <div v-if="michelinWidgetCustomisationSubmitErrors && michelinWidgetCustomisationSubmitErrors.group" class="invalid-feedback d-block">{{ tErrors('michelinWidgetCustomisation', 'group', michelinWidgetCustomisationSubmitErrors.group) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="default-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.default') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.default" type="checkbox" class="custom-control-input" id="default-checkbox">
                  <label class="custom-control-label" for="default-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-themes" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.theme') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <key-multiselect
                  v-model="michelinWidgetCustomisation.theme"
                  :options="themes"
                  track-by="value"
                  label="name"
                  open-direction="bottom"
                  :placeholder="$t('shared.placeholders.select')"
                  :allow-empty="false"
                  id="multiselect-themes" />
              </div>
            </div>

            <div class="form-group row">
              <label for="select-layout" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.layout') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <b-form-radio-group
                  id="btn-radios-layout"
                  v-model="michelinWidgetCustomisation.layout"
                  buttons
                  button-variant="outline-primary">
                  <b-form-radio
                    v-for="value in ['default', 'light', 'refreshed']"
                    :key="value"
                    :value="value">
                    <div class="d-flex flex-column align-items-center">
                      {{ $t(`attributes.michelinWidgetCustomisation.layouts.${value}`) }}
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <div class="form-group row">
              <label for="show-price-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.showPrice') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.showPrice" type="checkbox" class="custom-control-input" id="show-price-checkbox">
                  <label class="custom-control-label" for="show-price-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="input-radius" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.radius') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input
                  v-model.number="michelinWidgetCustomisation.radius"
                  type="range"
                  min="0"
                  max="200"
                  step="5"
                  class="form-control-range"
                  id="input-radius"
                  :placeholder="$t('attributes.michelinWidgetCustomisation.radius')">
                {{ michelinWidgetCustomisation.radius }} km
                <div v-if="michelinWidgetCustomisationSubmitErrors && michelinWidgetCustomisationSubmitErrors.radius" class="invalid-feedback">{{ tErrors('michelinWidgetCustomisation', 'radius', michelinWidgetCustomisationSubmitErrors.radius) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="fitters-button-as-buy-now-button-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.fittersButtonAsBuyNowButton') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.fittersButtonAsBuyNowButton" type="checkbox" class="custom-control-input" id="fitters-button-as-buy-now-button-checkbox">
                  <label class="custom-control-label" for="fitters-button-as-buy-now-button-checkbox"></label>
                </div>
              </div>
              <small class="col text-muted">{{ $t('attributes.michelinWidgetCustomisation.fittersButtonAsBuyNowButtonHelp') }}</small>
            </div>

            <div class="form-group row">
              <label for="display-whatsapp-link-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.displayWhatsappLink') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.displayWhatsappLink" type="checkbox" class="custom-control-input" id="display-whatsapp-link-checkbox">
                  <label class="custom-control-label" for="display-whatsapp-link-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="display-stock-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.displayStock') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.displayStock" type="checkbox" class="custom-control-input" id="display-stock-checkbox">
                  <label class="custom-control-label" for="display-stock-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="auto-select-only-retail-outlet-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.autoSelectOnlyRetailOutlet') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.autoSelectOnlyRetailOutlet" type="checkbox" class="custom-control-input" id="auto-select-only-retail-outlet-checkbox">
                  <label class="custom-control-label" for="auto-select-only-retail-outlet-checkbox"></label>
                </div>
              </div>
              <small class="col text-muted">{{ $t('attributes.michelinWidgetCustomisation.autoSelectOnlyRetailOutletHelp') }}</small>
            </div>

            <div class="form-group row">
              <label for="auto-select-only-fitter-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.autoSelectOnlyFitter') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.autoSelectOnlyFitter" type="checkbox" class="custom-control-input" id="auto-select-only-fitter-checkbox">
                  <label class="custom-control-label" for="auto-select-only-fitter-checkbox"></label>
                </div>
              </div>
              <small class="col text-muted">{{ $t('attributes.michelinWidgetCustomisation.autoSelectOnlyFitterHelp') }}</small>
            </div>

            <div class="form-group row">
              <label for="no-fitters-map-disabled-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.noFittersMapDisabled') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.noFittersMapDisabled" type="checkbox" class="custom-control-input" id="no-fitters-map-disabled-checkbox">
                  <label class="custom-control-label" for="no-fitters-map-disabled-checkbox"></label>
                </div>
              </div>
              <small class="col text-muted">{{ $t('attributes.michelinWidgetCustomisation.noFittersMapDisabledHelp') }}</small>
            </div>

            <div class="form-group row">
              <label for="no-fitters-no-map-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.noFittersNoMap') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.noFittersNoMap" type="checkbox" class="custom-control-input" id="no-fitters-no-map-checkbox">
                  <label class="custom-control-label" for="no-fitters-no-map-checkbox"></label>
                </div>
              </div>
              <small class="col text-muted">{{ $t('attributes.michelinWidgetCustomisation.noFittersNoMapHelp') }}</small>
            </div>

            <div class="form-group row">
              <label for="multiselect-first-geoloc-strategy" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.firstGeolocationStrategy') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <multiselect
                  v-model="michelinWidgetCustomisation.firstGeolocationStrategy"
                  :options="firstGeolocationStrategies"
                  open-direction="bottom"
                  :placeholder="$t('shared.placeholders.select')"
                  :allow-empty="true"
                  id="multiselect-first-geoloc-strategy"
                >
                  <template v-slot:singleLabel="{ option }">
                    {{ $t(`attributes.michelinWidgetCustomisation.firstGeolocationStrategies.${option}`) }}
                  </template>
                  <template v-slot:option="{ option }">
                    {{ $t(`attributes.michelinWidgetCustomisation.firstGeolocationStrategies.${option}`) }}
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <label for="show-fallback-products-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.showFallbackProducts') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.showFallbackProducts" type="checkbox" class="custom-control-input" id="show-fallback-products-checkbox">
                  <label class="custom-control-label" for="show-fallback-products-checkbox"></label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="show-msrp-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.showMsrp') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.showMsrp" type="checkbox" class="custom-control-input" id="show-msrp-checkbox">
                  <label class="custom-control-label" for="show-msrp-checkbox"></label>
                </div>
              </div>
            </div>

            <div v-if="michelinWidgetCustomisation.layout === 'refreshed'" class="form-group row">
              <label for="buy-now-button-blue-checkbox" class="col-sm-2">{{ $t('attributes.michelinWidgetCustomisation.refreshedButtonBlue') }}</label>
              <div class="col col-auto">
                <div class="custom-control custom-switch custom-switch-color">
                  <input v-model="michelinWidgetCustomisation.buyNowButtonBlue" type="checkbox" class="custom-control-input" id="buy-now-button-blue-checkbox">
                  <label class="custom-control-label" for="buy-now-button-blue-checkbox"></label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- Retailers tab -->
          <b-tab :title="$t('views.michelinWidgetCustomisations.tabs.retailers')">
            <!-- Disabled retailers -->
            <div class="form-group row">
              <label for="multiselect-retailers" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.disabledRetailers') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <!-- Disable retailer select -->
                <multiselect
                  @input="disableRetailer"
                  :options="availableRetailers"
                  :allow-empty="false"
                  :clear-on-select="false"
                  :placeholder="$t('shared.placeholders.select')"
                  :close-on-select="false"
                  :loading="retailersLoading"
                  open-direction="bottom"
                  class="mb-3"
                  id="multiselect-retailers">
                  <template slot="option" slot-scope="props">
                    <div class="gutter-x-xs">
                      <region-flag :code="props.option.region.code" />
                      <img :src="props.option.imgSmallUrl" style="max-height: 20px;">
                      {{ props.option.name }}
                    </div>
                  </template>
                </multiselect>

                <!-- Disabled retailers table -->
                <div v-if="michelinWidgetCustomisation.retailerExceptionIds.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table
                  class="table table-hover"
                  v-else>
                  <tbody>
                    <tr v-for="retailer in retailerExceptions" :key="retailer.id" :data-retailer-id="retailer.id">
                      <td class="td-shrink"><region-flag :code="retailer.region.code" /></td>
                      <td class="td-shrink"><img :src="retailer.imgSmallUrl" style="max-height: 20px;"></td>
                      <td>{{ retailer.name }}</td>
                      <td class="td-shrink text-nowrap">
                        <button type="button" class="btn btn-sm btn-danger" @click="enableRetailer(retailer)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <hr>

            <!-- Retailer customisations -->
            <div class="form-group row">
              <label for="multiselect-retailers-michelin-retailer-customisations" class="col-sm-2 col-form-label">
                {{ $t('attributes.michelinWidgetCustomisation.michelinRetailerCustomisations') }}
              </label>
              <div class="col-lg-4 col-sm-6 col-12">
                <!-- Add retailer select -->
                <multiselect
                  @input="addRetailerToMichelinRetailerCustomisations"
                  :options="availableRetailersForMichelinRetailerCustomisations"
                  :allow-empty="false"
                  :clear-on-select="false"
                  :placeholder="$t('shared.placeholders.addRetailer')"
                  :loading="retailersLoading"
                  open-direction="bottom"
                  class="mb-3"
                  id="multiselect-retailers-michelin-retailer-customisations">
                  <template slot="option" slot-scope="props">
                    <div class="gutter-x-xs">
                      <region-flag :code="props.option.region.code" />
                      <img :src="props.option.imgSmallUrl" style="max-height: 20px;">
                      {{ props.option.name }}
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-2"></div>
              <div class="col-sm-10 col-12">
                <!-- Retailer customisations table -->
                <div v-if="michelinWidgetCustomisation.michelinRetailerCustomisations.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table
                  class="table table-hover"
                  v-else>
                  <thead>
                    <tr>
                      <th class="th-shrink"></th>
                      <th class="th-shrink"></th>
                      <th>{{ $t('attributes.retailer.name') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.showRatings') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.transferZipcode') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.transferZipcodeKey') }}</th>
                      <th class="th-shrink" v-if="michelinWidgetCustomisation.displayWhatsappLink">{{ $t('attributes.michelinRetailerCustomisation.disableDisplayWhatsappLink') }}</th>
                      <th>{{ $t('attributes.michelinRetailerCustomisation.markerImg') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.transferVehicleSearch') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.transferVehicleSearchKey') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.hasFittersWithoutUrl') }}</th>
                      <th class="th-shrink">{{ $t('attributes.michelinRetailerCustomisation.showFreeDelivery') }}</th>
                      <th class="th-shrink" v-if="hasNewRetailerCustomisation"></th>
                      <th class="th-shrink"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="michelinRetailerCustomisation in michelinWidgetCustomisation.michelinRetailerCustomisations"
                      :key="michelinRetailerCustomisation.retailer.id"
                      :data-retailer-id="michelinRetailerCustomisation.retailer.id"
                      :class="{ 'table-danger': michelinRetailerCustomisation._destroy }"
                      style="line-height: 38px;">
                      <td><region-flag :code="michelinRetailerCustomisation.retailer.region.code" /></td>
                      <td><img :src="michelinRetailerCustomisation.retailer.imgSmallUrl" style="max-height: 20px;"></td>
                      <td>{{ michelinRetailerCustomisation.retailer.name }}</td>
                      <td class="text-center">
                        <dot :active="michelinRetailerCustomisation.showRatings" />
                      </td>
                      <td class="text-center">
                        <dot :active="michelinRetailerCustomisation.transferZipcode" />
                      </td>
                      <td>
                        <code v-if="michelinRetailerCustomisation.transferZipcode">{{ michelinRetailerCustomisation.transferZipcodeKey }}</code>
                      </td>
                      <td v-if="michelinWidgetCustomisation.displayWhatsappLink">
                        <dot :active="michelinRetailerCustomisation.disableDisplayWhatsappLink" />
                      </td>
                      <td class="td-shrink text-nowrap">
                        <img
                          v-if="michelinRetailerCustomisation.markerImg.url"
                          :src="michelinRetailerCustomisation.markerImg.url"
                          style="max-height: 38px;"
                          :class="{ 'border border-danger': michelinRetailerCustomisation.newMarkerImg, 'opacity-50': michelinRetailerCustomisation.removeMarkerImg }"
                        >
                        <img
                          v-if="michelinRetailerCustomisation.newMarkerImg"
                          :src="michelinRetailerCustomisation.newMarkerImg"
                          style="max-height: 38px;"
                          class="border border-success"
                        >
                      </td>
                      <td class="text-center">
                        <dot :active="michelinRetailerCustomisation.transferVehicleSearch" />
                      </td>
                      <td>
                        <code v-if="michelinRetailerCustomisation.transferVehicleSearch">{{ michelinRetailerCustomisation.transferVehicleSearchKey }}</code>
                      </td>
                      <td class="text-center">
                        <dot :active="michelinRetailerCustomisation.hasFittersWithoutUrl" />
                      </td>
                      <td class="text-center">
                        <dot :active="michelinRetailerCustomisation.showFreeDelivery" />
                      </td>
                      <td v-if="hasNewRetailerCustomisation">
                        <template v-if="!michelinRetailerCustomisation.id">
                          <span class="badge badge-warning">New</span>
                        </template>
                      </td>
                      <td class="text-nowrap">
                        <div class="gutter-x-xs">
                          <button type="button" class="btn btn-warning" @click="editMichelinRetailerCustomisation(michelinRetailerCustomisation)">
                            {{ $t('shared.actions.edit') }}
                          </button>
                          <template v-if="!michelinRetailerCustomisation._destroy">
                            <button type="button" class="btn btn-danger" @click="removeMichelinRetailerCustomisation(michelinRetailerCustomisation)">
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                              {{ $t('shared.actions.delete') }}
                            </button>
                          </template>
                          <template v-else>
                            <button type="button" class="btn btn-success" @click="restoreMichelinRetailerCustomisation(michelinRetailerCustomisation)">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                              {{ $t('shared.actions.restore') }}
                            </button>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Retailer customisation modal -->
            <b-modal
              v-if="michelinRetailerCustomisationModal"
              size="lg"
              id="michelinRetailerCustomisationModal"
              ref="michelinRetailerCustomisationModal"
              @ok="michelinRetailerCustomisationModalOk"
              no-fade>
              <template v-slot:modal-title>
                <div class="d-flex align-items-center gutter-sm">
                  <region-flag :code="michelinRetailerCustomisationModal.retailer.region.code" />
                  <img :src="michelinRetailerCustomisationModal.retailer.imgSmallUrl" style="max-height: 20px;">
                  <span>{{ michelinRetailerCustomisationModal.retailer.name }}</span>
                </div>
              </template>
              <form @submit.stop.prevent="michelinRetailerCustomisationModalSubmit()">
                <!-- Show ratings -->
                <div class="form-group row">
                  <label for="show-ratings-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.showRatings') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.showRatings" type="checkbox" class="custom-control-input" id="show-ratings-checkbox">
                      <label class="custom-control-label" for="show-ratings-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Transfer zipcode -->
                <div class="form-group row">
                  <label for="transfer-zipcode-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.transferZipcode') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.transferZipcode" type="checkbox" class="custom-control-input" id="transfer-zipcode-checkbox">
                      <label class="custom-control-label" for="transfer-zipcode-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Transfer zipcode key -->
                <div class="form-group row" v-if="michelinRetailerCustomisationModal.transferZipcode">
                  <label for="input-transfer-zipcode-key" class="col-sm-4 col-form-label">{{ $t('attributes.michelinRetailerCustomisation.transferZipcodeKey') }}</label>
                  <div class="col-sm-6 col-12">
                    <input
                      v-model="michelinRetailerCustomisationModal.transferZipcodeKey"
                      type="text"
                      class="form-control"
                      id="input-transfer-zipcode-key"
                      :placeholder="$t('attributes.michelinRetailerCustomisation.transferZipcodeKey')">
                  </div>
                  <small class="col-12 text-muted mt-2" v-html="$t('attributes.michelinRetailerCustomisation.transferZipcodeKeyHelp')" />
                </div>

                <!-- Disable display whatsapp link -->
                <div class="form-group row" v-if="michelinWidgetCustomisation.displayWhatsappLink">
                  <label for="disable-display-whatsapp-link-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.disableDisplayWhatsappLink') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.disableDisplayWhatsappLink" type="checkbox" class="custom-control-input" id="disable-display-whatsapp-link-checkbox">
                      <label class="custom-control-label" for="disable-display-whatsapp-link-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Marker img -->
                <div class="form-group row">
                  <label for="retailer-customisation-marker-img-input" class="col-sm-4 col-form-label">
                    {{ $t('attributes.michelinRetailerCustomisation.markerImg') }}
                  </label>
                  <div class="col-sm-6 col-12">
                    <img
                      v-if="michelinRetailerCustomisationModal.markerImg.url"
                      :src="michelinRetailerCustomisationModal.markerImg.url"
                      style="max-height: 38px;"
                      class="mb-2"
                      :class="{ 'border border-danger': michelinRetailerCustomisationModal.newMarkerImg, 'opacity-50': michelinRetailerCustomisationModal.removeMarkerImg }"
                    >
                    <img
                      v-if="michelinRetailerCustomisationModal.newMarkerImg"
                      :src="michelinRetailerCustomisationModal.newMarkerImg"
                      style="max-height: 38px;"
                      class="mb-2 border border-success"
                    >
                    <div v-if="michelinRetailerCustomisationModal.markerImg.url" class="custom-control custom-switch custom-switch-color mb-2">
                      <input v-model="michelinRetailerCustomisationModal.removeMarkerImg" type="checkbox" class="custom-control-input" id="remove-marker-checkbox">
                      <label class="custom-control-label" for="remove-marker-checkbox">
                        {{ $t('attributes.michelinRetailerCustomisation.removeMarkerImg') }}
                      </label>
                    </div>
                    <b-form-file
                      v-model="michelinRetailerCustomisationModal.markerImgFile"
                      @input="markerImgFileChanged($event, michelinRetailerCustomisationModal)"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      id="retailer-customisation-marker-img-input"
                    />
                  </div>
                </div>

                <!-- Transfer vehicle search -->
                <div class="form-group row">
                  <label for="transfer-vehicle-search-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.transferVehicleSearch') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.transferVehicleSearch" type="checkbox" class="custom-control-input" id="transfer-vehicle-search-checkbox">
                      <label class="custom-control-label" for="transfer-vehicle-search-checkbox"></label>
                    </div>
                  </div>
                </div>

                <template v-if="michelinRetailerCustomisationModal.transferVehicleSearch">
                  <!-- Transfer vehicle search key -->
                  <div class="form-group row">
                    <label for="input-transfer-vehicle-search-key" class="col-sm-4 col-form-label">{{ $t('attributes.michelinRetailerCustomisation.transferVehicleSearchKey') }}</label>
                    <div class="col-sm-6 col-12">
                      <input
                        v-model="michelinRetailerCustomisationModal.transferVehicleSearchKey"
                        type="text"
                        class="form-control"
                        id="input-transfer-vehicle-search-key"
                        :placeholder="$t('attributes.michelinRetailerCustomisation.transferVehicleSearchKey')">
                    </div>
                  </div>
                </template>

                <!-- Has fitters without url -->
                <div class="form-group row">
                  <label for="has-fitters-without-url-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.hasFittersWithoutUrl') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.hasFittersWithoutUrl" type="checkbox" class="custom-control-input" id="has-fitters-without-url-checkbox">
                      <label class="custom-control-label" for="has-fitters-without-url-checkbox"></label>
                    </div>
                  </div>
                </div>

                <!-- Free delivery -->
                <div class="form-group row">
                  <label for="free-delivery-checkbox" class="col-sm-4">{{ $t('attributes.michelinRetailerCustomisation.showFreeDelivery') }}</label>
                  <div class="col-sm-6 col-12">
                    <div class="custom-control custom-switch custom-switch-color">
                      <input v-model="michelinRetailerCustomisationModal.showFreeDelivery" type="checkbox" class="custom-control-input" id="free-delivery-checkbox">
                      <label class="custom-control-label" for="free-delivery-checkbox"></label>
                    </div>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-tab>

          <!-- Sorting tab -->
          <b-tab :title="$t('views.michelinWidgetCustomisations.tabs.sorting')">
            <!-- Relevance sort -->
            <h5>{{ $t('views.michelinWidgetCustomisations.form.relevanceSort') }}</h5>

            <div class="form-group row">
              <label for="multiselect-retailer-positions" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.michelinWidgetCustomisationRetailerPositions') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <!-- Add retailer select -->
                <multiselect
                  @input="addRetailerToRetailerPositions"
                  :options="availableRetailersForRetailerPositions"
                  :allow-empty="false"
                  :clear-on-select="false"
                  :placeholder="$t('shared.placeholders.addRetailer')"
                  :loading="retailersLoading"
                  open-direction="bottom"
                  class="mb-3"
                  id="multiselect-retailer-positions">
                  <template slot="option" slot-scope="props">
                    <div class="gutter-x-xs">
                      <region-flag :code="props.option.region.code" />
                      <img :src="props.option.imgSmallUrl" style="max-height: 20px;">
                      {{ props.option.name }}
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-2"></div>
              <div class="col-sm-10 col-12">
                <!-- Retailer positions table -->
                <div v-if="michelinWidgetCustomisation.michelinWidgetCustomisationRetailerPositions.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table
                  class="table table-hover"
                  v-else>
                  <thead>
                    <tr>
                      <th class="th-shrink"></th>
                      <th class="th-shrink"></th>
                      <th>{{ $t('attributes.retailer.name') }}</th>
                      <th>{{ $t('attributes.michelinWidgetCustomisationRetailerPosition.position') }}</th>
                      <th class="text-nowrap">{{ $t('attributes.michelinWidgetCustomisationRetailerPosition.last') }}</th>
                      <th class="th-shrink" v-if="hasNewRetailerCustomisation"></th>
                      <th class="th-shrink"></th>
                    </tr>
                  </thead>
                  <transition-group name="flip-list" tag="tbody">
                    <tr
                      v-for="retailerPosition in retailerPositionsSorted"
                      :key="retailerPosition.retailer.id"
                      :data-retailer-id="retailerPosition.retailer.id"
                      :class="{ 'table-danger': retailerPosition._destroy }"
                      style="line-height: 38px;">
                      <td><region-flag :code="retailerPosition.retailer.region.code" /></td>
                      <td><img :src="retailerPosition.retailer.imgSmallUrl" style="max-height: 20px;"></td>
                      <td>{{ retailerPosition.retailer.name }}</td>
                      <td class="td-shrink text-nowrap">
                        <input
                          v-if="!retailerPosition.last"
                          v-model.number="retailerPosition.position"
                          type="number"
                          min="1"
                          class="form-control"
                          :id="`retailer-${retailerPosition.retailer.id}-position-input`"
                          :placeholder="$t('attributes.michelinWidgetCustomisationRetailerPosition.position')">
                      </td>
                      <td class="td-shrink">
                        <div class="col-form-label">
                          <div class="custom-control custom-switch custom-switch-color">
                            <input v-model="retailerPosition.last" type="checkbox" class="custom-control-input" :id="`retailer-${retailerPosition.retailer.id}-last-checkbox`">
                            <label class="custom-control-label" :for="`retailer-${retailerPosition.retailer.id}-last-checkbox`"></label>
                          </div>
                        </div>
                      </td>
                      <td v-if="hasNewRetailerCustomisation">
                        <template v-if="!retailerPosition.id">
                          <span class="badge badge-warning">New</span>
                        </template>
                      </td>
                      <td class="text-nowrap">
                        <template v-if="!retailerPosition._destroy">
                          <button type="button" class="btn btn-danger" @click="removeMichelinWidgetCustomisationRetailerPosition(retailerPosition)">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                            {{ $t('shared.actions.delete') }}
                          </button>
                        </template>
                        <template v-else>
                          <button type="button" class="btn btn-success" @click="restoreMichelinWidgetCustomisationRetailerPosition(retailerPosition)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-undo-alt fa-w-16"><path fill="currentColor" d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z" class=""></path></svg>
                            {{ $t('shared.actions.restore') }}
                          </button>
                        </template>
                      </td>
                    </tr>
                  </transition-group>
                </table>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-retailers-order" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.orderedRetailers') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <!-- Add retailer select -->
                <multiselect
                  @input="addRetailerToOrder"
                  :options="availableRetailersForOrder"
                  :allow-empty="false"
                  :clear-on-select="false"
                  :placeholder="$t('shared.placeholders.addRetailer')"
                  :close-on-select="false"
                  :loading="retailersLoading"
                  open-direction="bottom"
                  class="mb-3"
                  id="multiselect-retailers-order">
                  <template slot="option" slot-scope="props">
                    <div class="gutter-x-xs">
                      <region-flag :code="props.option.region.code" />
                      <img :src="props.option.imgSmallUrl" style="max-height: 20px;">
                      {{ props.option.name }}
                    </div>
                  </template>
                </multiselect>

                <!-- Retailers order table -->
                <div v-if="michelinWidgetCustomisation.orderedRetailerIds.length === 0" class="alert alert-warning" role="alert">
                  {{ $t('shared.warnings.noRetailer') }}
                </div>
                <table
                  class="table table-hover"
                  v-else>
                  <draggable
                    tag="tbody"
                    v-model="michelinWidgetCustomisation.orderedRetailerIds"
                    handle=".handle">
                    <tr v-for="retailer in orderedRetailers" :key="retailer.id">
                      <th class="text-center handle cursor-move">
                        <svg aria-hidden="true" role="img" style="width: 0.625em; display: inline-block; font-size: inherit; height: 1em; overflow: visible; vertical-align: -.125em;" viewBox="0 0 270 512" xmlns="http://www.w3.org/2000/svg"><path d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z M204 208c26.5 0 48 21.5 48 48s-21.5 48 -48 48 -48 -21.5 -48 -48 21.5 -48 48 -48zM156 104c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48zm0 304c0 26.5 21.5 48 48 48s48 -21.5 48 -48 -21.5 -48 -48 -48 -48 21.5 -48 48z"></path></svg>
                      </th>
                      <td class="td-shrink"><region-flag :code="retailer.region.code" /></td>
                      <td class="td-shrink"><img :src="retailer.imgSmallUrl" style="max-height: 20px;"></td>
                      <td>{{ retailer.name }}</td>
                      <td class="td-shrink text-nowrap">
                        <button type="button" class="btn btn-sm btn-danger" @click="removeRetailerFromOrder(retailer)">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
                          {{ $t('shared.actions.delete') }}
                        </button>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </div>
            </div>

            <div class="form-group row">
              <label for="multiselect-retailer-fallback-sort" class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisation.retailerFallbackSort') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <multiselect
                  v-model="michelinWidgetCustomisation.retailerFallbackSort"
                  :options="retailerFallbackSorts"
                  :placeholder="$t('shared.placeholders.select')"
                  :allow-empty="false"
                  id="multiselect-retailer-fallback-sort" />
              </div>
            </div>
          </b-tab>

          <!-- Texts tab -->
          <b-tab :title="$t('views.michelinWidgetCustomisations.tabs.texts')">
            <multiselect
              v-if="availableLanguages.length > 0"
              @input="addLanguageData"
              :options="availableLanguages"
              :allow-empty="false"
              :clear-on-select="false"
              :placeholder="$t('shared.placeholders.select')"
              :close-on-select="false"
              :loading="groupsLoading"
              class="mb-3">
              <template v-slot:option="{ option }">
                <language-badge :language="option" />
              </template>
            </multiselect>

            <div v-if="michelinWidgetCustomisation.michelinWidgetCustomisationLanguageDatas.length === 0" class="alert alert-warning" role="alert">
              {{ $t('shared.warnings.noMichelinWidgetCustomisationLanguageData') }}
            </div>

            <b-tabs v-else nav-class="small" content-class="mt-3">
              <b-tab
                v-for="languageData in michelinWidgetCustomisation.michelinWidgetCustomisationLanguageDatas"
                :key="languageData.id"
                :title="`${languageData.language.code}-${languageData.language.region.code}`">
                <!-- HTML -->
                <div class="form-group row">
                  <label for="input-name" class="col-sm-2 col-form-label">HTML</label>
                  <div class="col-sm-10">
                    <div class="form-group">
                      <div class="codemirror-wrapper-sm">
                        <label class="col-form-label">{{ $t('attributes.michelinWidgetCustomisationLanguageData.additionalMessageHtml') }}</label>
                        <codemirror
                          v-model="languageData.additionalMessageHtml"
                          :options="{ mode: 'text/html', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="codemirror-wrapper-sm">
                        <label class="col-form-label">{{ $t('attributes.michelinWidgetCustomisationLanguageData.warningNoRetailOutletsHtml') }}</label>
                        <codemirror
                          v-model="languageData.warningNoRetailOutletsHtml"
                          :options="{ mode: 'text/html', lineNumbers: true, tabSize: 2, autoRefresh: true }" />
                      </div>
                    </div>
                  </div>
                </div>

                <hr>

                <!-- Texts -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisationLanguageData.texts') }}</label>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div v-for="textKey in textKeys" :key="textKey" class="form-group">
                      <label
                        :for="`input-${languageData.id}-${textKey}`"
                        class="col-form-label">
                        {{ $t(`attributes.michelinWidgetCustomisationLanguageData.description.${textKey}`) }}
                      </label>
                      <input
                        v-if="!textKey.startsWith('warning')"
                        v-model="languageData.texts[textKey]"
                        type="text"
                        class="form-control"
                        :id="`input-${languageData.id}-${textKey}`"
                        :placeholder="$t(`attributes.michelinWidgetCustomisationLanguageData.placeholder.${textKey}`)">
                      <textarea
                        v-else
                        v-model="languageData.texts[textKey]"
                        type="text"
                        class="form-control"
                        :id="`input-${languageData.id}-${textKey}`"
                        :placeholder="$t(`attributes.michelinWidgetCustomisationLanguageData.placeholder.${textKey}`)" />
                      <small v-if="$te(`attributes.michelinWidgetCustomisationLanguageData.format.${textKey}`)" class="text-muted">{{ $t(`attributes.michelinWidgetCustomisationLanguageData.format.${textKey}`) }}</small>
                    </div>
                  </div>
                </div>

                <hr>

                <!-- Retailer texts -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">{{ $t('attributes.michelinWidgetCustomisationLanguageData.retailerTexts') }}</label>
                  <div class="col-sm-10 col-12">
                    <b-tabs>
                      <b-tab v-for="retailer in availableRetailers" :key="retailer.id">
                        <template v-slot:title>
                          <div class="d-flex align-items-center gutter-sm">
                            <region-flag :code="retailer.region.code" />
                            <img :src="retailer.imgSmallUrl" style="max-height: 20px;">
                            <span>{{ retailer.name }}</span>
                          </div>
                        </template>

                        <div v-for="retailerTextKey in retailerTextKeys" :key="retailerTextKey" class="form-group">
                          <label
                            :for="`input-${languageData.id}-${retailerTextKey}`"
                            class="col-form-label">
                            {{ $t(`attributes.michelinWidgetCustomisationLanguageData.description.${retailerTextKey}`) }}
                          </label>
                          <input
                            v-model="languageData.retailerTexts[`retailer${retailer.id}${retailerTextKey}`]"
                            type="text"
                            class="form-control"
                            :id="`input-${languageData.id}-${retailerTextKey}`"
                            :placeholder="$t(`attributes.michelinWidgetCustomisationLanguageData.placeholder.${retailerTextKey}`)">
                          <small v-if="$te(`attributes.michelinWidgetCustomisationLanguageData.format.${retailerTextKey}`)" class="text-muted">{{ $t(`attributes.michelinWidgetCustomisationLanguageData.format.${retailerTextKey}`) }}</small>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="michelinWidgetCustomisationSubmitLoading">
            <md-spinner v-if="michelinWidgetCustomisationSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
            <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
            <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
          </button>
        </div>
      </form>
    </div>
  </transition>
</div>
